.client-ls-inner {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 23px 26px;
}

.client-ls-inner .ls-box {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 10px;
    min-height: 147px;
    padding: 32px 20px 15px 20px;
    position: relative;
}

.hover-box a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #405BFF;
    justify-content: center;
    grid-gap: 8.73px;
}

.hover-box a {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 245, 0.82);
    display: none;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.client-ls-inner .ls-box:hover .hover-box a {
    display: flex;
}

.client-name {
    font-weight: 300;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #282828;
    /* position: absolute; */
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
}

.form-field-client-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 11px;
    padding-top: 11px;
    padding-bottom: 17px;
}

button.delete-client {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F8 !important;
    border: 0.2px solid #282828;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #282828 !important;
    grid-gap: 14.33px;
}

.delete-modal-content h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 122%;
    display: flex;
    align-items: center;
    color: #282828;
    justify-content: center;
    margin-bottom: 20px;
}

.delete-modal-content p {
    font-weight: 300 !important;
    font-size: 19px !important;
    line-height: 21.96px !important;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #282828 !important;
    text-align: center !important;
    justify-content: center;
    margin-bottom: 30px !important;
}

.delete-modal-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 25px;
}

.client-ls-inner .ls-box-logo img {
    max-width: 180px;
    height: 100%;
    padding-bottom: 20px;
    width: 100%;
    height: 100px;
    object-fit: contain;
}

/*************Responsive Styles*****************/
/***************iPhone Portrait**************/
@media only screen and (max-width: 479px) and (min-width: 220px) {
    .client-ls-inner {
        grid-template-columns: 1fr;
    }

    .time-client-outer .search-container input,
    .time-client-outer a.new-client,
    .time-client-outer .button-sec-invoice {
        width: 100%;
    }

    .time-client-outer .time-client-top {
        flex-wrap: wrap;
        grid-gap: 30px;
    }

    .time-client-outer {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
    }

    .search-sec {
        width: 100%;
    }

    .ReactModal__Content.ReactModal__Content--after-open.delete-modal {
        width: 92% !important;
    }
}

/**********************iPhone Landscape*************/
@media only screen and (max-width: 767px) and (min-width: 480px) {
    .client-ls-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .time-client-outer {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
    }

    .ReactModal__Content.ReactModal__Content--after-open.client-modal {
        height: 70% !important;
        /* top: 60% !important; */
    }
}

/*************************iPad Portrait***************/
@media only screen and (max-width: 985px) and (min-width: 768px) {
    .client-ls-inner {
        grid-template-columns: repeat(3, 1fr);
    }
}

/**************iPad Landscape***************/
@media only screen and (max-width: 1024px) and (min-width: 986px) {
    .client-ls-inner {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1025px) {
    .client-ls-inner {
        grid-template-columns: repeat(3, 1fr);
    }
}