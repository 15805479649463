.clint-managment.client-management-project .client-detail-row-one {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 30px;
    flex-direction: row;
}
.client-detail-row-one-left{
    width: 40%;
    display: flex;
    align-items: center;
}
.client-detail-row-one-right{
    display: flex;
    grid-gap: 20px;
    justify-content: space-between;
    width: 55%;
    flex-wrap: wrap;
    flex-direction: row;
}
.client-detail-row-one-right .account-manager,
.client-detail-row-one-right .project-manager{
    width: 36% !important;
}
.client-detail-row-one-right .client-status{
    width: 18% !important;
}
.client-professional-details-outer.project-detai-outer-iner .account-manager-text,
.client-professional-details-outer.project-detai-outer-iner .client-status-text{
    color: #6A7988 !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 20.74px !important;
}
.clint-latest-project tbody.p-datatable-tbody tr:last-child td {
    border: none !important;
    /* padding-bottom: 8px !important; */
}
.account-manager.project-manager span.manager-profile-icon img{
    width: 27px;
    height: 27px;
    border-radius: 50%;
}
.account-manager-image-with-name {
    display: flex;
}
.account-manager-image-with-name-outer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 28px;
}
.account-manager.project-manager{
    padding-top: 29px;
    padding-bottom: 41px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 33px;
}
.account-manager.project-manager .account-manager-text {
    color: #454F59 !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18.3px !important;
    text-align: left;
    margin-bottom: 33px;
}
.account-manager-image-with-name-outer .account-manager-image-with-name {
    font-size: 18px;
    grid-gap: 15px;
    align-items: center;
}
@media screen and (max-width: 1401px) and (min-width: 1250px){
    .client-detail-row-one-left{
        width: 35%;
    }
    .client-detail-row-one-right{
        width: 61%;
    }
}
@media screen and (max-width: 1249px) {
    .client-detail-row-one-left{
        width: 100%;
    }
    .client-detail-row-one-right{
        width: 100%;
    }
}
@media screen and (max-width: 767px)  {
    .client-detail-row-one-right .account-manager, .client-detail-row-one-right .project-manager,
    .client-detail-row-one-right .client-status {
        width: 100% !important;
    }
    .client-with-invitation-clietnt{
        display: flex !important;
        grid-gap: 20px;
        flex-wrap: wrap;

    }
    .client-management-project {
        padding: 0 20px !important;
    }
   
}
@media screen and (max-width: 767px) and (min-width: 600px){
    .account-manager-image-with-name-outer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 599px) {
    .account-manager-image-with-name-outer {
        grid-template-columns: 1fr;
    }
}