.overview-outer-time .overview-top .overview-top-inner {
display: flex;
justify-content: space-between;
padding: 22px 35px 28px 20px;
align-items: center;
}

.overview-outer-time {
padding: 0 44px;
}
.input-field-inof.input-field-inof-new.input-field-create .cursor.open {
position: relative;
}
.overview-outer-time .overview-top .overview-top-left a {
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
display: flex;
align-items: center;
letter-spacing: 0.5px;
color: #ffffff;
background: #6479f8;
border-radius: 6px;
justify-content: center;
}
.date-picker-outer.date-picker-outer-all.expense-create-page-date span.text-red-500 {
  color: red;
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 99;
}
.s1-containauto.s1-containauto-client.new-exp-create .input-field-create {
  grid-gap: 9px !important;
}
.input-field-inof.input-field-inof-new.input-field-creat.expense-currency-create-new span#user-select {
  min-width: 200px;
}
.input-field-inof.input-field-inof-new.input-field-creat.expense-currency-create-new .custom-dropdown-new {
  padding-right: 0px !important;
}
.s1-containauto.s1-containauto-client.new-exp-create {
  padding: 0 38px !important;
}
.curency-dropdown-expense{
padding-top: 15px;
}
.radio-expense-create{
width: auto;
}
.content-outer.Expenses .overview-outer-time .overview-top .overview-top-left a:first-child {
width: auto !important;
height: 44px;
grid-gap: 8.5px;
}
.content-outer.Expenses span.icon-invoice img {
margin-right: 0 !important;
}
.overview-outer-time .overview-top .overview-top-left a:last-child {
/* width: 101px; */
padding: 0 15px;
height: 44px;
grid-gap: 8.5px;
}

.overview-outer-time .overview-top .overview-top-left {
display: flex;
grid-gap: 20px;
}

.overview-outer-time .overview-top .filter-inner.showing-result label {
font-weight: 300;
font-size: 12px;
line-height: 14.64px;
letter-spacing: 0.5px;
color: #282828;
}

.overview-outer-time .overview-top .filter-inner.showing-result {
display: flex;
align-items: center;
grid-gap: 10px;
}

.overview-outer-time .overview-top .filter-inner.showing-result select {
min-width: 44px;
height: 27px;
background-color: #fafafa;
border: 0.5px solid #b4b4b4;
border-radius: 5px;
font-weight: 500;
font-size: 11px;
line-height: 13.42px;
letter-spacing: 0.5px;
color: #282828;
padding-left: 10px;
background: url(../../images/icons/down-arrow-gray.png) no-repeat;
background-position: right 8px center;
padding-right: 10px;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
outline: none;
}

.overview-outer-time .filter-sec-overview .filter-inner input,
.overview-outer-time .filter-sec-overview .filter-inner select,
.selected-options1 {
background-color: #ffffff !important;
border: 0.2px solid rgba(106, 121, 136, 0.7);
border-radius: 5px;
height: 43px;
padding: 0px 16px 0px 16px;
color: #78828d;
font-size: 17px;
max-width: 188px;
}

.overview-outer-time
.filter-sec-overview
input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
width: 121px !important;
font-weight: 300;
font-size: 13px;
line-height: 15.86px;
letter-spacing: 0.5px;
color: #858585;
border: 0;
}

.overview-outer-time svg.rs-picker-toggle-caret.rs-icon {
left: auto !important;
}

.overview-outer-time input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
padding-left: 11px !important;
padding-right: 50px !important;
}

.overview-outer-time
.filter-sec-overview
.rs-picker-default
.rs-picker-toggle.rs-btn {
width: 121px !important;
border-radius: 5px;
}

.overview-outer-time .filter-sec-overview .filter-inner select,
.selected-options1 {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url("../../images/icons/down-arrow.svg") no-repeat;
background-position: right 8px center;
cursor: pointer;
padding-right: 30px;
}

.filter-sec-overview .invoice-filter-inner {
display: flex;
flex-direction: row;
grid-gap: 18px;
align-items: flex-end;
justify-content: space-between;
}

.filter-sec-overview .invoice-filter-inner .filter-inner {
display: flex;
flex-direction: column;
align-items: baseline;
grid-gap: 7px;
}

.filter-sec-overview input.rs-picker-toggle-textbox {
padding-left: 11px !important;
padding-right: 40px !important;
font-weight: 300;
font-size: 13px !important;
line-height: 15.86px;
letter-spacing: 0.5px;
color: #858585 !important;
text-transform: lowercase;
}

.tab-inner-time .graph-piechart {
display: flex;
width: 100%;
flex-wrap: wrap;
justify-content: space-between;
grid-gap: 18px;
}

.tab-inner-time .overview-graph-time {
width: 66%;
}

.piechart {
width: 32%;
}

.filter-sec-overview {
max-width: 100000px;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
}

.filter-sec-overview {
margin-bottom: 22px;
}

/* Your stylesheet */
.tracker-date-picker .rdrDateRangeWrapper input {
display: none;
}

.overview-outer-time .filter-sec-overview .filter-inner input,
.overview-outer-time .filter-sec-overview .filter-inner select {
/* width: 182px; */
}

.filter-sec-overview input.rs-picker-toggle-textbox {
width: 121px !important;
}

.filter-sec-overview .invoice-filter-inner .filter-inner label {
color: #282828;
font-size: 13px;
font-style: normal;
font-weight: 300;
line-height: 15.86px;
letter-spacing: 0.5px;
}

.overview-outer-time .filter-sec-overview .filter-inner select {
max-width: 180px;
min-width: 180px;
}

.filter-inner.invoice-type select {
max-width: 180px;
min-width: 180px;
}

.overview-outer-time
.filter-sec-overview
.tracker-date-picker
.rs-picker-toggle.rs-btn.rs-btn-default {
width: 100% !important;
}

.overview-outer-time
.filter-sec-overview
.tracker-date-picker
input#date-range {
width: 100% !important;
max-width: 100% !important;
/* padding-right: 30px !important; */
}

.overview-outer-time
.filter-sec-overview
.tracker-date-picker
span.rs-picker-toggle-value {
display: none;
}

.overview-outer-time .filter-sec-overview .tracker-date-picker {
display: inline-block;
max-width: 215px;
vertical-align: middle;
width: 215px !important;
background: #fff;
}

.filter-sec-overview.active .invoice-filter-inner,
.invoice-filter-inner.active .invoice-filter-inner-section {
/* overflow-x: clip; */
width: 100%;
}

.filter-sec-overview.active,
.invoice-filter-inner.active,
.invoice-filter-inner.active .invoice-filter-inner-section {
overflow: visible !important;
display: flex;
}

.dropdown-list1 .checkbox-label1,
.dropdown-list1 .selected-option1 {
color: #282828 !important;
font-size: 17px !important;
font-weight: 300;
line-height: 20.74px !important;
letter-spacing: 1px !important;
border-bottom: 0.5px solid #d8dde1;
display: flex;
align-items: center;
padding: 16px 5px;
white-space: break-spaces;
text-align: left;
word-break: break-word;
}

.dropdown-list1 .checkbox-label1:last-child,
.dropdown-list1 .selected-option1:last-child {
border-bottom: none;
margin-bottom: 0;
}

.dropdown-list1::-webkit-scrollbar {
width: 5px;
}

/* Track */
.dropdown-list1::-webkit-scrollbar-track {
background: transparent;
margin-top: 5px;
margin-bottom: 5px;
border-radius: 50px;
}

/* Handle */
.dropdown-list1::-webkit-scrollbar-thumb {
background: #96a8ba;
background-clip: padding-box;
border-radius: 50px;
}

th.fixed-se .p-column-title {
padding-top: 4px !important;
display: flex;
}

td.fixed-se input {
margin-top: 4px !important;
display: flex;
}

/* Handle on hover */
.dropdown-list1::-webkit-scrollbar-thumb:hover {
background: transparent;
}

label.checkbox-label1 input {
margin-right: 13px !important;
}

.selected-options1 {
display: flex;
align-items: center;
}

.selected-option-count {
background: #405bff;
color: #fff;
padding: 3px 6px;
border-radius: 50%;
font-size: 10px;
margin-left: 7px;
font-weight: 500;
}
/* Hide the default checkbox */
input[type="checkbox"] {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
width: 16px;
height: 16px;
border-radius: 50%;
border: 1px solid #6a7988;
outline: none;
cursor: pointer;
position: relative;
}
th.time-tracker-top-checkbox input[type="checkbox"]:checked::before {
content: "-";
color: #fff;
font-weight: 500;
position: absolute;
top: 1px;
left: 9.7px;
transform: translate(-50%, -50%);
opacity: 1;
background: none !important;
font-size: 28px;
}
th.time-tracker-top-checkbox input[type="checkbox"]:checked {
background: #6a7988;
border: 1px solid #6a7988;
}
/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked::before {
content: ""; /* Clear default content */
width: 100%;
height: 100%;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'%3E%3Cpath d='M1 4L3.5 6.5L9 1' stroke='white' stroke-width='1.4'/%3E%3C/svg%3E");
background-repeat: no-repeat;
background-position: center; /* Center the SVG */
position: absolute; /* Position the pseudo-element */
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
opacity: 1; /* Show the tick mark */
}
input[type="checkbox"]:checked {
background: #405bff;
border: 1px solid #405bff;
}
/* Hide the checkmark by default */
input[type="checkbox"]::before {
content: "";
display: none;
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked::before {
display: block; /* Show the checkmark */
}
/* .time-table th:nth-child(3) {
padding: 0;
padding-left: 5px;
} */
.bulk-edit button {
border-radius: 6px;
border: 0.2px solid #282828;
background: #f5f6f8;
padding: 9px 10px;
color: #405bff;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17.08px;
letter-spacing: 0.5px;
display: flex;
align-items: center;
grid-gap: 7px;
text-transform: capitalize;
width: 96px;
height: 32px;
}
.bulk-edit .delete-tracker-details {
color: #282828;
}
.bulk-edit .delete-tracker-details img {
width: 15.867px;
height: 17px;
}
.bulk-edit button img {
width: 13.28px;
height: 13.36px;
margin-left: 0;
}
.content-outer.Expenses {
padding-left: 0 !important;
padding-right: 0 !important;
}
.content-outer.Expenses .client-document-outer {
padding-left: 0 !important;
padding-right: 0 !important;
}

.outer-project-client-section.expense-cat .invoice-top{
padding-left: 0;
padding-right: 0;
}
.outer-project-client-section.expense-cat .filter-inner.showing-result.show-result-team-fill{
margin-right: 0;
}
.date-range-new {
position: absolute;
z-index: 9999;
right: 0;
border-radius: 8px !important;
border: 0.2px solid #6A7988;
background: #FFF;
margin-top: 10px;
}
.date-range-new span.rdrDayNumber span{
color: #6A7988;
}
.date-range-new .rdrDayPassive .rdrDayNumber span{
color: #d5dce0;
}
.date-range-new.right-align {
right: 0;
left: auto;
}
.date-range-new.left-align {
left: 0;
right: 0;
width: min-content;
}
.date-range-new .rdrDefinedRangesWrapper {
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
border-color: #D8DDE1;
padding: 22px 0 25px 0;
border-top-right-radius: 0px;
width: 190px;
border-top-right-radius: 0px;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange{
padding: 15px 28px 15px 34px;
color: #282828;
font-size: 17px;
font-weight: 300;
line-height: 44.2px;
letter-spacing: 1px;
border: none;
width: auto;
background: transparent;
text-transform: lowercase;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange:first-letter{
text-transform: uppercase;
}
.date-range-new .rdrDefinedRangesWrapper button.rdrStaticRange.rdrStaticRangeSelected{
color: #282828 !important;
font-size: 17px;
font-weight: 500;
line-height: 44.2px;
letter-spacing: 1px;
background: rgba(245, 246, 248, 0.90);
text-transform: none;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange .rdrStaticRangeLabel{
padding: 0;
display: flex;
white-space: nowrap;
text-transform: capitalize;
}
.date-range-new .rdrInputRanges {
display: none;
}
.date-range-new .rdrMonthName {
color: #282828;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.date-range-new .rdrDateDisplayWrapper {
display: none;
}
.date-range-new span.rdrMonthAndYearPickers select {
color: #282828;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.date-range-new .rdrStaticRange:hover .rdrStaticRangeLabel,
.date-range-new .rdrStaticRange:focus .rdrStaticRangeLabel{
background-color: transparent;
}
.date-range-new span.rdrDayNumber {
color: #96A8BA;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -0.465px;
width: 40px;
height: 40px;
top: 0;
}
.date-range-new .rdrDay{
width: 40px;
height: 40px;
background: transparent !important;
}
.date-range-new span.rdrWeekDay {
color: #96A8BA;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 31px;
}
.ReactModal__Content .date-range-new {
position: absolute;
z-index: 99;
border-radius: 8px !important;
border: 0.2px solid #6A7988;
background: #FFF;
top: 30px;
left: 0;
width: max-content;
max-width: 98%;
margin: 0 auto;
/* top: auto !important;
bottom: auto !important;
right: auto;
left: auto;
transform: translate(-69%, -50%); */
}
.rdrDateRangePickerWrapper{
width: 100%;
}
/* .date-range-new .rdrMonth .rdrWeekDays{
grid-gap: 30px;
}
.date-range-new .rdrWeekDay {
flex-basis: auto;
} */
.date-range-new  .rdrMonth {
width: 25.667em;
}
.date-range-new .rdrMonthAndYearPickers{
display: none;
}
.date-range-new .rdrMonthName {
text-align: center;
}
.date-range-new .rdrCalendarWrapper{
position: relative;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
}
.date-range-new .rdrMonthAndYearWrapper {
position: absolute;
width: 100%;
padding-top: 44px;
height: auto;
padding-left: 28px;
padding-right: 43px;
}
.date-range-new .rdrNextPrevButton:before {
content: "";
background: url(../../images/icons/arrow-circle-left.svg);
position: absolute;
left: 0;
top: 0;
width: 24px;
height: 24px;
}
.date-range-new .rdrNextPrevButton{
position: relative;
background: transparent !important;
margin: 0;
width: 24px !important;
height: 24px !important;
/* margin-left: 28px; */
}
.date-range-new button.rdrNextPrevButton.rdrNextButton {
transform: rotate(180deg);
/* margin-right: 43px;
margin-left: 0 !important; */
}
.date-range-new .rdrNextPrevButton i{
display: none;
}
.date-range-new .rdrMonthName {
padding: 39px 0 37px 0;
}
.date-range-new .rdrMonth{
padding: 0;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal {
padding-left: 23px;
padding-right: 34px;
padding-bottom: 107px;
border-top-right-radius: 8px !important;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child {
padding-right: 25px;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
padding-left: 25px;
}
.date-range-new .new-calendar-button {
position: absolute;
bottom: 36px;
right: 51px;
display: flex;
grid-gap: 32px;
}
.date-range-new .new-calendar-button button{
background: transparent !important;
color: #282828 !important;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #6A7988 !important;
font-size: 18px !important;
font-style: normal !important;
font-weight: 300 !important;
line-height: normal;
padding: 0 !important;
width: auto !important;
}
.date-range-new .rdrSelected {
background-color: #405BFF !important; /* Your custom color here */
color: #ffffff !important; /* Text color for better contrast */
}

.date-range-new .rdrStartEdge::before,
.date-range-new .rdrEndEdge::before {
content: "";
position: absolute;
left: 0;
top: 0;
background-color: #405BFF !important;
width: 40px;
height: 40px;
border-radius: 50%;
z-index: 99;
}
.date-range-new .rdrStartEdge::after,
.date-range-new .rdrEndEdge::after {
content: "";
position: absolute;
left: 0;
top: 0;
background-color: #fff;
z-index: 9;
}
.date-range-new .rdrStartEdge,
.date-range-new .rdrEndEdge{
border: none !important;
background-color: transparent !important;
background: transparent !important;
}
.date-range-new .rdrStartEdge:after,
.date-range-new .rdrEndEdge:after{
content: "";
position: absolute;
left: 0;
top: 0;
width: 50%;
height: 100%;
background-color: #E9ECFD;
border-top: 0.2px solid #405BFF;
border-bottom: 0.2px solid #405BFF;  
}
.date-range-new .rdrStartEdge:after{
right: 0 !important;
left: auto !important;
}
span.rdrStartEdge.rdrEndEdge:after{
display: none;
}
.date-range-new .rdrStartEdge,
.date-range-new .rdrEndEdge,
.date-range-new .rdrInRange {
background-color: #E9ECFD;
border-top: 0.2px solid #405BFF;
border-bottom: 0.2px solid #405BFF;  
width: 40px;
height: 40px;
top: 0;
left: 0;
/* position: relative; */
border-radius: 0;
}
.rdrDayToday .rdrDayNumber span:after{
background-color: #405BFF;
}
.date-range-new .rdrDayStartPreview{
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
}
.date-range-new .rdrDayEndPreview{
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
}
.date-range-new .rdrDayStartPreview,
.date-range-new .rdrDayEndPreview,
.date-range-new .rdrDayInPreview{
width: 40px;
height: 40px;
left: 0;
top: 0;
}
.date-range-new span.rdrDayNumber span{
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
z-index: 99;
}
.date-range-new .rdrStartEdge,
.date-range-new .rdrEndEdge,
.date-range-new .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span{
color: #405BFF !important;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -0.465px;
}
.ReactModal__Content.ReactModal__Content--after-open .date-range-new .rdrDay {
width: 40px;
height: 40px;
}

.content-outer.Expenses .overview-top {
position: relative;
}
/* expensecreate */
.s1-containauto.new-exp-create .sheet-manual-header .cursor {
cursor: pointer;
position: relative;
}
.s1-containauto.new-exp-create .detail-add-parteditmanual-mobile{
  display: none;
}
.s1-containauto.new-exp-create .sheet-manual-header  label.form-label {
color: #454F59;
font-size: 15px;
font-weight: 600;
line-height: 122%;
}
.s1-containauto.new-exp-create .sheet-manual-header .form-field-client .custom-dropdown-user-new {
  width: 172px;
  display: block !important;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 32px;
}
.s1-containauto.new-exp-create{
padding-left: 0 !important;
padding-right: 0 !important;
}
.s1-containauto.new-exp-create .sheet-manual-header .form-field-client {
width: fit-content;
display: flex;
align-items: center;
gap: 17px;
}
.s1-containauto.new-exp-create .sheet-manual-header .input-field-inof label{
color: #454F59;
font-size: 15px;
font-weight: 600;
line-height: 122%;
}
.s1-containauto.new-exp-create .sheet-manual-header .input-field-inof.input-field-inof-new{
display: flex;
align-items: center;
gap: 17px;
}
.s1-containauto.new-exp-create .sheet-manual-header {
padding: 20px 0px 17px 39px;
border-bottom: 1px solid #e8e8e8;
}
.s1-containauto.new-exp-create .user-and-client-sec{
flex-wrap: wrap;
flex-direction: row;
width: 100%;
padding: 21px 92px 37px 39px;
margin: 0;
border: none;
}
.s1-containauto.new-exp-create .user-and-client-sec .Select.Expense.User {
width: 100%;
}
.s1-containauto.new-exp-create .user-and-client-sec .Select.Expense.User {
text-align: left;
width: 100%;
display: flex;
flex-direction: column;
}
.s1-containauto.new-exp-create  td.sub-vatpr-tb-fisl{
font-weight: 500;
font-size: 19px;
color: #656565;
line-height: 23px;
}
.s1-containauto.new-exp-create .user-and-client-sec .Select.Expense.User label{
color: #282828;
font-size: 17px;
font-weight: 500;
line-height: 122%;
margin-bottom: 11px;
}
.s1-containauto.new-exp-create .user-and-client-sec .Select.Expense.User .cursor {
cursor: pointer;
position: relative;
}
.s1-containauto.new-exp-create .user-and-client-sec .form-field-client {
width: 100%;
text-align: left;
display: flex;
flex-direction: column;
}
.s1-containauto.new-exp-create .user-and-client-sec .form-field-client label{
color: #282828;
font-size: 17px;
font-weight: 500;
line-height: 122%;
margin-bottom: 11px;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part {
padding: 24px 37px 89px 47px;
border-top: 1px solid #e8e8e8;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part .other-subhead1 textarea {
border: none;
width: 100%;
height: 62px;
padding: 0;
margin-top: 6px;
border-radius: 5px;
outline: none;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part .file-add-p1-attachment {
padding-top: 0;
}

.s1-containauto.new-exp-create .sec-after-tab-ls-part th.sub-invoto-tb-fisl span {
font-size: 15px;
}
.s1-containauto.new-exp-create .detail-add-parteditmanual thead.the-abantant-edittxh tr th {
padding-left: 10px;
color: #282828;
font-size: 17px;
font-weight: 500;
line-height: 122%;
}
.s1-containauto.new-exp-create  thead.the-abantant-edittxh {
border-bottom: 1px solid #E8E8E8 !important;
border-top: 1px solid #E8E8E8 !important;
}
.form-field-client-mobile-billable{
display: none;
}
.s1-containauto.new-exp-create .other-head1-mobile{
display: none;
}
.s1-containauto.new-exp-create .mobile-cteate-expense-btn{
display: none;
}
.s1-containauto.new-exp-create .style-portion-browsetext.mobile {
display: none;
}
.s1-containauto.new-exp-create .attachment-head-mobile {
display: none;
}
.s1-containauto.s1-containauto-client.new-exp-create .date-due-manual-edm.input-field-create {
  padding-right: 0 !important;
}
.m-edit-main.exp-new-top-create .form-field-client{
  position: relative;
}
.m-edit-main.exp-new-top-create .form-field-client span.text-red-500.text-xs.italic,
.expense-currency-create-new span.text-red-500.text-xs.italic{
  position: absolute;
  bottom: -20px;
  left: 0;
}
.expense-currency-create-new{
  position: relative;
}
.expense-modal-scroll{
  height: max-content;
  overflow: auto;
  max-height: 40vh;
  padding-bottom: 20px;
  padding-right: 0px !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.sprint-modal-expense{
  padding-right: 25px !important;
}
.invoice-filter-top-date.invoice-filter-top-date.rs-picker.rs-picker-date.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-auto.rs-picker-read-only.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default {
    background: #fafafa !important;
    border: 0.5px solid #b4b4b4 !important;
    border-radius: 5px !important;
    background-color: #fafafa !important;
    cursor: no-drop; 
    color: rgba(40, 40, 40, 0.4) !important;
}
.invoice-filter-top-date.invoice-filter-top-date.rs-picker.rs-picker-date.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-auto.rs-picker-read-only.rs-picker-has-value.rs-picker-cleanable input.rs-picker-toggle-textbox.rs-picker-toggle-read-only{
  background-color: #fafafa !important;
  cursor: no-drop !important;
  color: rgba(40, 40, 40, 0.4) !important;
}
@media only screen and (max-width: 991px) {
.s1-containauto.new-exp-create .style-portion-browsetext.mobile {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  position: relative;
  height: 43px;
} 
.m-edit-main.exp-new-top-create{
  padding-right: 0 !important;
}
.s1-containauto.new-exp-create .attachment-head-mobile {
  color: #6A7988;
  font-size: 17px;
  font-weight: 300;
  line-height: 122%;
  padding-bottom: 9px;
  display: block;
}
.s1-containauto.new-exp-create .add-item-button-end-browse {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 0;
  border-left: 1px solid #ddd;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 5px 5px 0px;
background: #F3F3F5;
border: none;
height: 41px;
}

.s1-containauto.new-exp-create .add-item-button-end-browse img {
  margin-right: 5px;
}

.s1-containauto.new-exp-create .upload-file-name-br {
  flex: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 8px;
}

.s1-containauto.new-exp-create .sheet-manual-header {
padding:26px 14px 34px 14px;
display: flex;
flex-direction: column-reverse;
gap:31px;
}
.s1-containauto.new-exp-create .fs-head-autoinvo {
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 29px;
padding-top: 21px;
}
.s1-containauto.new-exp-create .other-head1-mobile{
  display: block;
  color: #6A7988;
  font-size: 17px;
  font-weight: 300;
  line-height: 122%;
}
.s1-containauto.new-exp-create .sheet-manual-header .input-field-inof label {
color: #6A7988;
font-size: 17px;
font-style: normal;
font-weight: 300;
line-height: 122%;
/* margin-bottom: -10px; */
}
.s1-containauto.new-exp-create .other-head1{
display: none;
}
.s1-containauto.new-exp-create .mobile-cteate-expense-btn{
display: block;
}
.s1-containauto.new-exp-create .mobile-cteate-expense-btn button a{
display: block;
border-radius: 6px;
background: #6479F8;
color: #FFF;
text-align: center;
font-size: 14px;
font-weight: 500;
line-height: 122%;
letter-spacing: 0.5px;
padding: 12px 0 15px 0;
}
.s1-containauto.new-exp-create .mobile-cteate-expense-btn button {
padding: 0;
width: 100%;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part .other-subhead1 textarea {
border: 0.2px solid rgba(106, 121, 136, 0.70);
border-radius: 7px;
height: 80px;
padding: 15px;
margin-bottom: 32px;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part .file-add-p1-attachment{
display: none;
}
.s1-containauto.new-exp-create .invo-total-specialcard{
display: none;
}
.s1-containauto.new-exp-create .sec-after-tab-ls-part {
padding: 0 17px 40px 17px;
border-top: none;
}
.form-field-client-mobile-billable {
display: flex;
align-items: center;
gap: 14px;
padding: 0;
}
.s1-containauto.new-exp-create .sheet-manual-header .form-field-client:last-child{
display: none;
}
.form-field-client-mobile-billable.radio-sec.radio-expense-create label {
color: #6A7988;
font-size: 17px;
font-weight: 300;
line-height: 122%;
}
.s1-containauto.new-exp-create .sheet-manual-header .form-field-client {
flex-direction: column;
align-items: flex-start;
width: 100%;
gap: 9px;
}
.s1-containauto.new-exp-create .sheet-manual-header .cursor{
width: 100%;
}
.s1-containauto.new-exp-create .sheet-manual-header .form-field-client .custom-dropdown-user-new {
width: 100% !important;
}
.s1-containauto.new-exp-create .sheet-manual-header .input-field-inof.input-field-inof-new {
display: flex;
align-items: flex-start;
gap: 9px;
flex-direction: column;
width: 100%;
}
.s1-containauto.new-exp-create .date-due-manual-edm {
display: flex;
align-items: flex-start;
padding-right: 0;
flex-direction: column;
gap: 9px;
width: 100%;
}
.s1-containauto.new-exp-create .date-due-manual-edm .invo-date-due-cal {
width: 100%;
}
.s1-containauto.new-exp-create .calender-date-due-pic .rs-picker-toggle.rs-btn.rs-btn-default {
width: 100% !important;
}
.s1-containauto.new-exp-create .user-and-client-sec {
padding: 49px 14px 19px 14px;
gap: 26px;
}
.s1-containauto.new-exp-create .user-and-client-sec label.form-label {
color: #6A7988;
font-size: 17px;
font-weight: 300;
line-height: 122%;
}
.s1-containauto.new-exp-create .sheet-manual-header label.form-label {
color: #6A7988;
font-size: 17px;
font-weight: 300;
line-height: 122%;
}
.invo-date-issue-tile.also-for-dued-cal{
color: #6A7988;
font-size: 17px;
font-weight: 300;
line-height: 122%;
}
.s1-containauto.new-exp-create .Select.Expense.User label.form-label{
color: #6A7988;
font-size: 17px;
font-weight: 300;
line-height: 122%;
}
.s1-containauto.new-exp-create .mobile-revert {
display: flex;
flex-direction: column-reverse;
}

.s1-containauto.new-exp-create .detail-add-parteditmanual {
  display: none;
}
.s1-containauto.new-exp-create .detail-add-parteditmanual-mobile{
  display: flex;
  align-items: flex-start;
  padding:34px 14px 0 14px;
}
.s1-containauto.new-exp-create tbody {
width: 100%;  
}
.s1-containauto.new-exp-create tbody tr {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 28px;
}
.s1-containauto.new-exp-create tbody tr td{
  width: 100%;
}
.s1-containauto.new-exp-create tbody input.input-validation-error1 {
  width: 100%;
  border-radius: 7px;
  border: 0.2px solid rgba(106, 121, 136, 0.70);
  background: #FFF;
  height: 43px;
  padding: 9px 16px 11px;
}
.s1-containauto.new-exp-create .mobile-label-with-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}
.s1-containauto.new-exp-create .mobile-expense-label-head {
  color: #6A7988;
  font-size: 17px;
  font-weight: 300;
  line-height: 122%;
}

}
@media only screen and (max-width: 1152px) and (min-width: 993px) {
.s1-containauto.new-exp-create .sheet-manual-header {
display: grid;
grid-template-columns: 1fr 1fr;
}
}
/* end-expensecreate */

@media only screen and (max-width: 1200px) and (min-width: 850px) {
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
padding-left: 0px !important;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child {
padding-right: 0px !important;
}
}
/* @media only screen and (max-width: 1245px) and (min-width: 850px) {
.rdrDateRangePickerWrapper{
flex-wrap: wrap;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRanges{
flex-direction: row;
white-space: nowrap;
}
.date-range-new .rdrDefinedRangesWrapper{
width: 100%;
max-width: 1000px;
overflow: auto;
padding: 5px 0;
}
.date-range-new{
width: 66vw;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal {
padding-left: 25px;
padding-right: 25px;
}
.date-range-new .rdrMonthAndYearWrapper{
padding-left: 45px;
padding-right: 45px;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange{
padding: 15px !important;
}
.date-range-new .rdrCalendarWrapper {
width: 100%;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal {
width: 100%;
justify-content: center;
}
} */
@media (min-width: 1300px) {
.outer-project-client-section.expense-cat {
max-width: 59.26%;
}
table.p-datatable-table {
min-width: 59.82% !important;
}
}
@media (max-width: 768px) {
.dropdown-list1 {
top: auto !important;
left: 23px !important;
width: 90% !important;
}
.curency-dropdown.curency-dropdown-expense.manage-expense-outer {
flex-wrap: wrap;
padding-top: 0;
margin-bottom: 10px;
}
.custom-dropdown1 {
position: static !important;
}
}

@media only screen and (min-width: 1440px) {
.filter-sec-overview .invoice-filter-inner,
.filter-sec-overview {
margin-bottom: 11px;
}
}

@media only screen and (max-width: 1440px) and (min-width: 1025px) {
.overview-outer-time .overview-top .overview-top-inner {
padding: 22px 0px 28px 0px;
}

.filter-sec-overview .invoice-filter-inner {
padding-bottom: 11px;
/* margin-bottom: 11px; */
}

.overview-outer-time {
margin: 0 40px;
padding: 0;
}

.tab-outer-time ul.tabs {
padding-left: 40px;
padding-right: 40px;
}
}

@media only screen and (max-width: 1025px) {
.overview-outer-time .overview-top .overview-top-inner {
padding: 22px 0px 28px 0px;
}
.s1-containauto.s1-containauto-client.new-exp-create {
  padding: 0 20px !important;
}
.filter-sec-overview .invoice-filter-inner {
padding-bottom: 11px;
margin-bottom: 11px;
}

.overview-outer-time {
margin: 0 20px;
padding: 0;
}

.tab-outer-time ul.tabs {
padding-left: 20px;
padding-right: 20px;
}
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
.overview-outer-time .overview-top .overview-top-inner {
flex-direction: column;
align-items: baseline;
grid-gap: 30px;
}
}

/* Basic styling for the dropdown */
.custom-dropdown1 {
position: relative;
min-width: 181px;
}

.placeholder {
width: 100%;
height: 100%;
text-align: left;
padding-top: 10px;
}

/* Styling for the selected options area */
/* .selected-options1 {
display: flex;
flex-wrap: wrap;
padding: 5px;
border: 1px solid #ccc;
border-radius: 4px;
min-height: 32px;
cursor: pointer;
} */

/* .selected-option1 {
display: inline-flex;
align-items: center;
padding: 2px 6px;
margin: 2px;
background-color: #e0e0e0;
border-radius: 4px;
} */

.selected-option1 .remove-option1 {
margin-left: 4px;
cursor: pointer;
font-weight: bold;
}

/* Styling for the dropdown list of options */
.dropdown-list1 {
/* display: none; */
position: absolute;
top: 53px;
left: 0;
border-radius: 8px;
background: #fff;
box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
width: 317px;
max-height: 343px;
z-index: 9999;
max-height: 343px;
/* Set your desired max height */
overflow-y: auto;
padding: 17px;
padding-top: 1px;
overflow-x: hidden;
}

.checkbox-label1 {
display: block;
padding: 8px;
cursor: pointer;
}

.checkbox-label1 input[type="checkbox"] {
-webkit-appearance: none;
appearance: none;
}

.checkbox-label1 input[type="checkbox"] {
border: 1px solid #405bff !important;
color: #ffffff;
/* color set here so that Windows' High-Contrast Mode can override */
height: 14px !important;
position: relative;
width: 14px !important;
padding: 0 !important;
vertical-align: -5px;
border-radius: 50% !important;
min-width: 14px;
}

.checkbox-label1 input[type="checkbox"]::before {
color: #fff;
}

.checkbox-label1 input[type="checkbox"]:checked {
background: #405bff !important;
}

.checkbox-label1 input[type="checkbox"]:checked::before {
/* use `visibility` instead of `display` to avoid recalculating layout */
visibility: visible;
}

/* input[type="checkbox"]:disabled {
background: darkgrey;
border-color: rgb(175, 129, 175);
color: gray;
} */
/* Show the dropdown list when the selected-options area is clicked */
.custom-dropdown1:focus-within .dropdown-list1 {
display: block;
}

/* subin */

.overview-top-right.expense-overview,
.overview-top-right.equipment-overview {
flex-direction: column;
}
.manage a {
display: flex;
align-items: center;
color: #6479f8;
font-weight: 600;
width: max-content;
}
.manage svg path {
fill: #6479f8;
}
.manage a span {
margin-right: 6px;
display: flex;
align-items: center;
}
.manage {
margin-top: 15px;
}
