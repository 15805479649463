.document-upload-inner{
    display: flex;
    grid-gap: 21px;
    flex-direction: row;
    justify-content: center;
}
.document-upload-box{
    width: 130px;
    height: 130px;
    border-radius: 7px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}
.ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal {
    width: 702px !important;
    height: 356px !important;
    max-width: 702px !important;
    padding: 29px 58px 51px 58px !important;
}
.icon-upload {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.subtitle-document{
    color: #454F59;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.96px;
    margin-bottom: 43px;
}
.modal-top-sec-with-close {
    display: flex;
    flex-direction: row;
    margin-bottom: 52px;
    position: relative;
}
.modal-top-sec-with-close .modal-close-invite.outter-modal-main {
    padding-top: 0;
    top: 0;
    position: absolute;
    right: -25px;
}
.modal-top-sec-with-close .modal-client-head h2{
    margin-bottom: 0 !important;
    color: #282828;
    font-size: 22px;
    font-weight: 500;
    line-height: 22.44px;
    padding-top: 5px;
}
.modal-top-sec-with-close .modal-close-invite.outter-modal-main a{
    padding: 0 !important;
    display: flex;
}
.upload-name input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    text-indent: -1800%;
    cursor: pointer;
}
.ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal.google-drive-modal{
    height: 534px !important;
    padding: 29px 31px 31px 40px !important;
    max-height: 80vh !important;
    overflow: auto;
}
.ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal.google-drive-modal .modal-top-sec-with-close .modal-close-invite.outter-modal-main {
    padding-top: 0;
    top: 0;
    position: absolute;
    right: 0;
}
.google-drive-account-select .selected-option.custom-dropdown-user-new img {
    width: 27px;
    height: 27px;
}
.google-drive-account-select .selected-option.custom-dropdown-user-new {
    display: flex;
    align-items: center;
    justify-content: normal !important;
    grid-gap: 7px;
}
.google-drive-table{
    margin-top: 41px;
}
.google-drive-top {
    display: flex;
    align-items: center;
    grid-gap: 17px;
}
.google-drive-account-select {
    width: 100%;
}
.search-input-show input{
    color: #282828;
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
    border-radius: 7px !important;
    width: 98%;
    height: 43px !important;
    padding-left: 16px;
    font-weight: 300;
    line-height: 23px;
    font-size: 19px;
    display: flex !important;
    align-items: center !important;
}
.google-drive-table .p-datatable-wrapper {
    /* max-height: 300px;
    overflow: auto; */
    border-radius: 15px;
background: #FFF;
box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
}
.google-drive-table tbody.p-datatable-tbody>tr td {
    padding: 16px 0 9px 22px !important;
}
.google-drive-table .p-datatable .p-datatable-thead>tr>th:last-child {
    padding-right: 0;
    border-bottom-right-radius: 0;
}
.google-drive-table .p-datatable .p-datatable-thead>tr>th:first-child {
    border-bottom-left-radius: 0 !important;
}
.search-input-show {
    margin-top: 0;
    position: absolute;
    width: 100%;
    top: -45px;
}
.google-drive-outer {
    position: relative;
}
.search-icon {
    cursor: pointer;
}
.client-document-outer {
    /* padding-left: 39px;
    padding-right: 45px; */
    padding-left: 39px;
    padding-right: 45px;
}
.overview-top-right.client-document-outer-top-section {
    align-items: flex-start;
    margin: 32px 0px;
    margin-left: 11px;
    margin-bottom: 7px !important;
    /* margin-top: 29.6px; */
}
.client-document-outer .filter-inner.showing-result.show-result-team-fill{
    margin-top: 0 !important;
}
.overview-top-right.client-document-outer-top-section .text-arrow-outer {
    margin: 0;
    text-align: left;
}
.client-document-outer .showing-count.showing-count-timeoff .filter-inner.showing-result.show-result-team-fill{
    margin-right: 0;
}
.client-document-outer .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:first-child{
    border-bottom-left-radius: 0 !important;
}
.client-document-outer .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:last-child{
    border-bottom-right-radius: 0 !important;
}
.document-client-top-sec .invoice-top {
    padding-left: 19px;
    padding-right: 9px;
}
.user-outer-timeoff.document-client-top-sec .datatable-team-maintb td:last-child {
    text-align: left;
}
@media only screen and (max-width: 768px) {
    .ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal.google-drive-modal {
        width: 96% !important;
        
    }
    .document-client-top-sec .invoice-top {
        padding-left: 0px;
        padding-right: 0px;
    }
    .ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal {
        width: 90% !important;
        padding: 30px !important;
        height: auto !important;
    }
    .document-upload-inner {
        flex-wrap: wrap;
}
.modal-top-sec-with-close .modal-close-invite.outter-modal-main {
    right: -8px;
}
.google-drive-table .p-datatable-wrapper {
    max-width: 10000px;
    width: max-content;
}
.google-drive-table .p-datatable.p-component.p-datatable-resizable.p-datatable-resizable-fit.p-datatable-responsive-scroll{
    overflow: auto;
}
.google-drive-table .p-datatable .p-datatable-thead>tr>th {
    width: 100px !important;
}
}
@media only screen and (max-width: 1200px) and (min-width: 993px) {
    .ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal.google-drive-modal,
    .ReactModal__Content.ReactModal__Content--after-open.document-upoload-modal{
        transform: translate(-33%, -50%) !important;
    }
}
div#\:2\.contentEl,
.picker.shr-bb-shr-cb-shr-he.picker-dialog-content {
    /* max-height: 500px !important;
    max-width: 100% !important; */
   
}
.picker.shr-bb-shr-cb.picker-dialog {
    /* transform: translate(15%, 9%);
    max-height: 500px !important;
    max-width: 1000px !important; */
}