.back-btn-ins {
  text-align: initial;
}

.button-container {
  display: flex;
  gap: 10px;
}

.btnf1-si-s::before {
  content: "";
  position: absolute;
  top: 6px;
  width: 6px;
  height: 12px;
  background-image: url("../../../../images/user/back-btn-arrow.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  left: 47px;
}
.invoice-main-body.invoice-main-body-client {
  grid-template-columns: 1fr;
}
.back-btn-ins {
  position: relative;
  border-bottom: 1px solid #d8dde1;
  padding-bottom: 18px;
  padding-left: 47px;
  margin-left: -41px;
  margin-right: -37px;
}

a.btnf1-si-s {
  margin-left: 17px;
  font-weight: 300;
  font-size: 18px;
  color: #282828;
  text-decoration: none;
  line-height: 22px;
}

a.invoice-num-01 {
  font-weight: 700;
  font-size: 26px;
  line-height: 31.72px;
  color: #454f59;
}

.bill-num-si {
  text-align: initial;
  display: flex;
  align-items: center;
  grid-gap: 28px;
  white-space: nowrap;
}

.s1-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0px 20px 18px;
  grid-gap: 20px;
}

.bill-num-si .approve-top {
  border-radius: 100px;
  border: 0.2px solid #516D4D;
  background: #D5F3D8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 31px;
  color: #70AD69;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.bill-num-si .reject-top {
  border-radius: 100px;
  border: 0.2px solid #fe6161;
  background: #fe616130;
  color: #ed0b0b;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 31px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.btn-aary a {
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  line-height: 17px;
  margin-right: 0px;
  max-height: 44px;
}

a.editbtn-in-sle img {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

a.editbtn-in-sle {
  background: #6479f8;
  color: #ffff;
  padding: 12px 12px 12px 14px;
  border-radius: 6px;
  width: 139px;
}

a.editbtn-in-slp {
  border: 0.2px solid #282828;
  border-radius: 6px;
  padding: 12px 11px 11px 15px;
  width: 92px;
  background: #f5f6f8;
}

a.editbtn-in-slp img {
  width: 24px;
  height: 21px;
  margin-right: 6px;
}

.btn-aary {
  display: flex;
  align-items: flex-end;
  overflow: auto;
  max-width: 1000px;
  width: 100%;
  justify-content: end;
  grid-gap: 15px;
}
.tab-inner-time .btn-aary a {
  margin-right: 0;
}
.tab-inner-time .m-edit-main{
  padding-right: 0 !important;
}
.btn-aary a{
  white-space: nowrap;
}
a.editbtn-in-sld img {
  width: 18px;
  height: 20px;
}

a.editbtn-in-sld {
  padding: 11px 17px 14px 19px;
  border: 0.2px solid #282828;
  border-radius: 6px;
  margin-right: 0px;
  width: 110px;
  background: #f5f6f8;
}

a.editbtn-in-sld img {
  margin-right: 5px;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.main-head-bs11 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
  border-bottom: 1px solid #e8e8e8;
  text-align: left;
}

.main-head-bs11 a {
  display: block;
  text-align: initial;
}

.invoice-detail-bs1 {
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  width: 100%;
  padding: 22px 45px 55px 44px;
}

.invo-id {
  font-weight: 600;
  font-size: 15px;
  color: #454f59;
  line-height: 18px;
  padding-bottom: 4px;
}

.invo-num-1 {
  font-weight: 300;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
}

td.tab-totalvat {
  color: #656565 !important;
  font-size: 16px !important;
}

.head-con-date {
  text-align: initial;
  font-weight: 300;
  font-size: 19px;
  color: #282828;
  line-height: 23px;
  padding: 20px 0;
}

.invo-from-to {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: initial;
}

td.invo-totalfd {
  line-height: 21px;
}

.invo-from-sing {
  max-width: 259px;
}

.invo-to-sing {
  max-width: 259px;
}

.from-sing-head {
  font-weight: 500;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
  padding-bottom: 10px;
}

.to-sing-head {
  font-weight: 500;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
  padding-bottom: 10px;
}

.name-sing-head {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 12px;
  padding-bottom: 7px;
}

.toname-sing-head {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 12px;
  padding-bottom: 7px;
}

.styll2 {
  font-weight: 300;
  font-size: 13.89px;
  color: #6a7988;
  line-height: 19px;
}

.table-outtter {
  padding-top: 35px;
}

tr.mainborder-bottom td {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 20px;
}

tr.mainborder-bottom td {
  padding: 10px 0;
  padding-right: 20px;
}

tr.border-bottomiteam td {
  font-weight: 300;
  font-size: 16px;
  color: #282828;
  line-height: 20px;
  padding: 15px 0px;
  word-break: break-word;
}

tr.sub-bordertotal.reborder td {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 21px;
  /* border-top: 1px solid #E8E8E8; */
  padding: 13px 0px;
}

tr.vat-bordertotal.reborder td {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 20px;
  padding: 2px 0px;
}

tr.total-bordertotal.reborder td {
  font-weight: 500;
  font-size: 17px;
  color: #282828;
  padding: 12px 0px;
}

/* .reborder:last-child {
    border-bottom: 1px solid #E8E8E8;
} */
/* tr.sub-bordertotal.reborder {
    border-top: 1px solid #E8E8E8;
} */
table.invo-single-tb1 {
  width: 100%;
  text-align: left;
}

.other-comment {
  text-align: initial;
  padding-top: 20px;
}

.other-head1 {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #454f59;
}

.other-subhead1 {
  font-weight: 300;
  font-size: 13.89px;
  line-height: 20px;
  color: #6a7988;
}

.attachment-single-invi {
  text-align: left;
}

.attachment-head {
  font-weight: 600;
  font-size: 15px;
  color: #454f59;
  line-height: 19px;
  padding-bottom: 13px;
}

.attachment-single-invi {
  padding-top: 63px;
}

.the-src-pdf-file {
  padding-top: 2px;
  background: #f5f6f8;
  max-width: 179px;
  border-radius: 6px;
  padding-left: 12px;
  padding-bottom: 7px;
  height: 37px;
  margin-bottom: 15px;
}
.the-src-pdf-file:last-child{
  margin-bottom: 0;
}
.attachment-file-sib-psize {
  font-weight: 300;
  font-size: 8.89px;
  color: #6a7988;
  line-height: 19px;
}

.attachment-file-sib-pname {
  font-weight: 300;
  font-size: 13px;
  color: #282828;
  line-height: 16px;
}

.invoice-manage-singlepg-head {
  font-weight: 500;
  font-size: 22px;
  color: #454f59;
  line-height: 26px;
}

.invo-main-status-head {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #282828;
  padding-top: 22px;
}

.invo-sig-st-btns {
  display: grid;
}

.manage-detail-bs2 {
  text-align: initial;
  background: #f5f6f8;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 15px;
  padding: 34px 30px;
  height: fit-content;
  max-width: 220px;
  width: 100%;
  min-width: 147px;
}

.manage-detail-outer {
  display: flex;
  grid-gap: 14px;
  flex-wrap: wrap;
  width: 100%;;
}

a.Approve-singlebtn {
  color: #7cc273;
  border: 1px solid #70ad69;
  border-radius: 8px;
  width: 100% !important;
  max-width: 250px !important;
  height: 44px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin-top: 13px;
  line-height: 17px;
}

a.Approve-singlebtn.approved,
.approved {
  background: #D5F3D8;
  color: #70AD69;
  cursor: not-allowed;
}

a.NotReviewed-singlebtn {
  color: #c39a6f;
  border: 1px solid #b59468;
  border-radius: 8px;
  width: 131px;
  height: 44px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin-top: 13px;
  line-height: 17px;
}

a.NotReviewed-singlebtn.Notreviewed {
  background: #FDDFB5;
  color: #E39C4F;
  cursor: not-allowed;
}

a.Reject-singlebtn.rejected {
  background: #fe616130;
  color: #ed0b0b;
  cursor: not-allowed;
}

a.Approve-singlebtn.approved img,
a.Reject-singlebtn.rejected img {
  margin-left: 5px;
}

a.Approve-singlebtn img {
  margin-left: 5px;
  width: 23px;
  height: 15px;
}
.invoice-cancel-new a {
  color: rgb(40, 40, 40);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  padding: 13px 20px;
  border-radius: 8px;
  border: 1px solid #282828;
  background: #F5F6F8;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 11px;
  margin-top: 13px;
  height: 44px;
  max-width: 250px;
}
a.Reject-singlebtn {
  color: #fe6161;
  border: 1px solid #fe6161;
  border-radius: 8px;
  width: 100%;
  max-width: 250px;
  height: 44px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  line-height: 17px;
}

a.Reject-singlebtn img {
  margin-left: 5px;
  width: 11px;
  height: 11px;
}

.payment-btn-sin-in {
  padding-top: 19px;
}

.payment-btn-sec-head {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #282828;
}

.btn-pay-cta12 {
  display: grid;
}

a.payin-singlebtn {
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  line-height: 17px;
  width: auto;
  max-width: 250px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.2px solid #282828;
  border-radius: 6px;
  margin-top: 11px;
  padding: 5px 10px;
}

a.prma-singlebtn {
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  line-height: 17px;
  width: auto;
  max-width: 250px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.2px solid #282828;
  border-radius: 6px;
  margin-top: 8px;
  padding: 5px 10px;
}

.pay-app-det2 {
  display: grid;
  padding-top: 27px;
}

.head-payapp {
  font-weight: 500;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
  padding-bottom: 9px;
}

.company-detail {
  padding-top: 27px;
  display: grid;
}

.companynae-sig {
  font-weight: 300;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
  word-break: break-all;
}

.invoice-main-body {
  display: grid;
  grid-template-columns: 1fr 220px;
  grid-gap: 0px 15px;
}

.react-outeroi {
  padding-bottom: 67px;
  margin: 0 auto;
}

.invoice-detail-bs1 {
  background: #fff;
}

/* tr.border-bottomiteam:nth-child(3) td {
  padding-top: 0;
} */
tr.mainborder-bottom {
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
}

td.tab-totalsubvat {
  font-weight: 300 !important;
  font-size: 16px !important;
  color: #282828 !important;
  line-height: 20px !important;
}

.single-ls-info {
  padding-top: 18px;
}

.content-outer {
  background: #fbfbfd;
  min-height: 100vh;
  padding-bottom: 50px;
}

td.invo-totalfd {
  line-height: 21px;
}

td.tab-totalamt {
  font-size: 16px !important;
  line-height: 20px;
}

.admin-outer.Invoice_single .content-outer {
  padding: 0px;
}

.admin-outer.invoice_single .content-outer {
  padding: 0px !important;
}

.man-padd-for-siginvo {
  /* padding-right: 37px;
  padding-left: 41px; */
  margin: 0 auto;
  max-width: 2000px;
}

td.invo-totalfd,
td.tab-totalamt {
  padding-bottom: 25px !important;
}

table.invo-single-tb1.table-sec-main {
  display: contents;
}

.other-comment {
  border-top: 1px solid #e8e8e8;
}

/* tr.border-bottomiteam:nth-child(3) td {
  border-bottom: 1px solid #e8e8e8;
} */
.content-outer.invoice-single-class {
  padding-left: 0px;
  padding-right: 0px;
}

a.editbtn-in-sldow {
  border: 0.2px solid #282828;
  border-radius: 6px;
  padding: 14px 17px 14px 13px;
  width: 119px;
  background: #F5F6F8;
}

a.editbtn-in-sldow img {
  margin-right: 5px;
  width: 11px;
  height: 16px;
  margin-top: -2px;
}

.content-outer.invoice-single-class {
  padding-left: 0px;
  padding-right: 0px;
}

a.editbtn-in-sldow {
  border: 0.2px solid #282828;
  border-radius: 6px;
  padding: 14px 17px 14px 13px;
  width: 119px;
  background: #F5F6F8;
}

a.editbtn-in-sldow img {
  margin-right: 5px;
  width: 11px;
  height: 16px;
  margin-top: -2px;
}

tr.sub-bordertotal.reborder {
  position: relative;
  border-top: 1px solid #e8e8e8;
  display: table-row-group;
}

/* tr.sub-bordertotal.reborder:before{
  content: "";
  position: absolute;
  height: 1px;
  background: #e8e8e8;
  width: 100%;
} */

h2.the-modal-siglepg-head {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: #282828 !important;
  text-align: center;
  margin-bottom: 26px;

}

.close-outtermain-modal {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

img.closebtn-siglemodal-popup {
  filter: grayscale(1);
}

a.editbtn-in-sldow {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.largecreate-invo-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-in-action-btn-arry {
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin: auto 0;
  padding-right: 20px;
}

.btn-siglemodal-popup {
  display: flex !important;
}

.outter-modal-main.delmodalicon {
  display: flex;
  justify-content: flex-end;
}

.modal.del-pop-modal {
  text-align: center;
}

.button-container.btn-2-in-modal {
  justify-content: center;
}

.modal.del-pop-modal h2 {
  color: #282828;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

.modal-content-del p {
  color: #282828;
  font-size: 17px !important;
  font-weight: 300 !important;
  line-height: 22px;
  margin-bottom: 25px !important;
  padding-top: 16px;
}

.delete-modal-buttons-reject {
  display: flex;
  justify-content: center;
  grid-gap: 25px;
}

.form-field-payment .form-label {
  font-weight: 300;
  font-size: 17px;
  line-height: 20.74px;
  color: #6A7988;
  margin-bottom: 9px;
  display: inline-block;
}

.form-field-payment .form-input,
.form-field-payment .form-select {
  height: 43px;
}

.form-field-payment .form-input,
.form-field-payment .form-select {
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
}

.form-field-payment .form-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../../images//icons/down-arrow.svg') no-repeat;
  background-position: right 24px center;
  cursor: pointer;
  padding-right: 30px;
}

.form-field-payment .form-select:focus {
  outline: none;
  border-color: #4a90e2;
}

.buttons-rejected {
  background: #ff5555fd !important;
  color: #faf9f9 !important;
}

.buttons-approved {
  background: #139820 !important;
  color: #ffffff !important;
}

.buttons-pay {
  background: #139820 !important;
  color: #ffffff !important;
}

.buttons-pay-cancel {
  background: #f78b8bfd !important;
  color: #ffffff !important;
}

/* .content-outer.invoices {
  padding-left: 0;
  padding-right: 0;
} */
.bottom-download-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

button.buttons-pay.buttons-pay-disabled {
  background: #ccc !important;
}

.paynow-invoice-sec {
  margin-top: 30px;
}

@media screen and (min-width: 1440px) {
  .content-outer.invoices .content-inner .man-padd-for-siginvo {
    width: 1103px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 400px) {

  a.editbtn-in-sle img,
  a.editbtn-in-slp img,
  a.editbtn-in-sld img {
    margin-right: 2px;
  }
}
@media screen and (max-width: 580px) {
  .s1-contain {
    display: flex !important;
    flex-direction: column;
    grid-gap: 20px;
    align-items: baseline;
  }
}
@media screen and (max-width: 991px) {
  a.editbtn-in-sldow {
    display: none;
  }
  .btn-aary {
    padding-bottom: 15px;
  }
  .fs-head-autoinvo .btn-aary{
    padding-bottom: 0;
  }
  .man-padd-for-siginvo {
    /* padding-left: 15px; */
    padding-right: 15px;
  }

  .invoice-main-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .s1-contain {
    /* display: block; */
  }

  .s1-contain {
    padding: 22px 0px 13px 0px;

  }

  .back-btn-ins {
    display: none;
  }

  .bill-num-si {
    padding-bottom: 0px;
  }

  .manage-detail-bs2 {
    /* max-width: initial; */
    padding: 17px 17px 34px 22px;
    max-width: 100%;

  }

  .invo-sig-st-btns {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    align-items: baseline;
    grid-gap: 0px 20px;
    flex-wrap: wrap;
  }

  a.Approve-singlebtn img {
    /* margin-left: 10px; */
  }

  a.Reject-singlebtn img {
    margin-left: 10px;

  }

  a.Reject-singlebtn {
    /* max-width: 147px; */
    width: 100%;
  }

  a.Approve-singlebtn {
    /* max-width: 147px; */
    width: 100%;
  }

  tr.sub-bordertotal.reborder td {
    padding: 24px 0px 13px 0px;

  }

  tr.sub-bordertotal.reborder,
  tr.total-bordertotal.reborder {
    /* width: 100vh !important; */
  }

  .btn-pay-cta12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: baseline;
    grid-gap: 0px 20px;
  }

  a.payin-singlebtn,
  a.prma-singlebtn {
    /* width: 147px; */
  }

  .payment-comp-detail {
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 40px;
    flex-wrap: wrap;
  }

  .main-head-bs11 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 36px 23px;
    margin-right: 20px;
    text-align: initial;
  }

  .invoice-detail-bs1 {
    margin-top: 24px;
    padding: 22px 0px 55px 26px;
  }

  .invo-from-to {
    display: block;
  }

  .invo-to-sing {
    padding-top: 27px;
  }

  table.invo-single-tb1 {
    width: 100%;
    white-space: nowrap;
    display: inline-table;
  }

  .table-inner-outmain22 {
    overflow: scroll;
  }

  tr.border-bottomiteam td {
    padding-right: 20px;
  }

  tr.mainborder-bottom td {
    min-width: 69px !important;
  }

  tr.total-bordertotal.reborder td {
    padding-right: 20px;
  }

  .head-con-date {
    max-width: 271px;
  }

  table.invo-single-tb1 tr.border-bottomiteam:last-of-type {
    border-bottom: 1px solid rgb(220, 22, 22) !important;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  a.editbtn-in-sldow {
    display: block;
  }

  .head-con-date {
    max-width: initial;
  }

  .invo-from-to {
    display: grid;
    align-items: flex-start;
  }

  table.invo-single-tb1 {
    width: 100%;
  }

  td.mob-no-need {
    display: revert
  }

}

@media only screen and (max-width: 991px) and (min-width: 700px) {
  .invoice-detail-bs1 {
    padding: 22px 15px 55px 26px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 618px) {
  table.invo-single-tb1 {
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  td.mob-no-need {
    display: none;
  }
}

@media only screen and (max-width: 1112px) and (min-width: 992px) {
  .invoice-main-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .btn-pay-cta12 {
    grid-gap: unset;
  }

  .invo-sig-st-btns {
    grid-gap: unset;
  }

  .manage-detail-bs2 {
    max-width: initial;
    padding: 17px 17px 34px 22px;
  }

  .invo-sig-st-btns {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    align-items: baseline;
    grid-gap: 0px 20px;
    
  }
  a.Approve-singlebtn img {
    /* margin-left: 10px; */
  }

  a.Reject-singlebtn img {
    margin-left: 10px;
  }

  a.Reject-singlebtn {
    /* width: 147px; */
  }

  a.Approve-singlebtn {
    /* width: 147px; */
  }

  tr.sub-bordertotal.reborder td {
    padding: 24px 0px 13px 0px;
  }

  tr.sub-bordertotal.reborder,
  tr.total-bordertotal.reborder {
    width: 100vh !important;
  }

  .btn-pay-cta12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: baseline;
    grid-gap: 0px 20px;
  }

  a.payin-singlebtn,
  a.prma-singlebtn {
    /* width: 147px; */
  }

  .payment-comp-detail {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    justify-content: flex-start;
    grid-gap: 40px;
  }

  .invoice-detail-bs1 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 450px) {
  .btn-pay-cta12{
    grid-template-columns: 1fr;
  }
  .invo-sig-st-btns{
    grid-template-columns: 1fr;
  }
}