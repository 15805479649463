.play {
  margin-left: 20px;
  cursor: pointer;
}

.stop {
  margin-left: 20px;
  cursor: pointer;
}

.task {
  color: green;
  padding-right: 10px;
}

.cursor {
  cursor: pointer;
}

.sub:hover {
  color: red;
  background-color: #8e8c8c;
}

/* .card-header{
    position-relative
} */
.text-field-with-error {
  border: 2px solid;
  border-color: #a6a1a1;
  border: 1px solid red !important;
  outline-offset: 0px;
}

.card {
  margin-bottom: 25px;
}

.disabled-text {
  pointer-events: none;
}

.deleted {
  background-color: #e77b7b;
}

.time-range {
  font-size: 12px;
}

.date-pick-width {
  width: 162px;
}

.Header {
  padding-top: 20px;
}

.container-head {
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  border-color: red;
  background-color: #d9e2d9;
}

.flex-head {
  padding-top: 10px;
}

.button-pad {
  margin: 6px;
}

.card-bg {
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #f5f6f8;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
}

.card-header-sec.row {
  display: flex;
  padding: 17px 23px 17px 23px;
  border-bottom: 0.5px solid #d8dde1;
  align-items: center;
  grid-gap: 25px;
}

.separation-head {
  height: 34px;
  width: 1px;
  background: #d8dde1;
}

.card-header-date.col time {
  color: #454f59;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.84px;
}

.card-timer.col {
  color: #6a7988;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.84px;
}

.SmallInput {
  width: 42px !important;
  height: 30px;
  margin: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-bg {
  background-color: #e8e4e4 !important;
}

.trash-color {
  color: red;
}

.pro-modal {
  background-color: red;
  top: "50%";
  left: "50%";
  right: "auto";
  bottom: "auto";
  margin-right: "-50%";
  transform: "translate(-50%, -50%)";
}

.modal-size {
  width: 460px;
  height: 500px;
}

.padding-sub {
  margin-top: 20px;
}

ul {
  /* Remove default bullet points */
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  /* Add padding and margin to the list items */
  /* padding: 10px; */
  /* margin-bottom: 10px; */
  /* Add a border to separate the items */
  /* border: 1px solid #ccc; */
  /* Add a hover effect */
  /* transition: background-color 0.3s ease;
  justify-content: space-between;
  align-items: center; */
}

.card-header-date.col {
  font-size: 22px;
  font-style: normal;
  line-height: 26.84px;
  color: #454f59;
  font-weight: 600;
}

.list-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-arrow {
  margin-left: 10px;
  /* Adjust the margin as needed */
}

li:hover {
  /* background-color: #dad9d9; */
}

.green {
  color: green;
}

.cancel {
  background-color: #ff8a00 !important;
}

.timetracker-overview-outer {
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #fff;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  padding: 7px 9px 7px 11px;
  margin-bottom: 40px;
  /* max-width: 10000px;
  overflow: auto; */
  position: relative;
}

.timetracker-inner .project-name .cursor.col input {
  border-radius: 7px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #fff;
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  padding: 7px 9px 7px 11px;
  width: 100%;
  height: 37px;
}

.timetracker-inner.data-time-tracker.overview-sec-timer
  .project-name
  .cursor.col
  input.input-edit-project {
  border-radius: 7px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #fff;
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  padding: 7px 9px 7px 11px;
  width: 100%;
  height: 37px;
}

.timetracker-inner.data-time-tracker.overview-sec-timer .project-name input {
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  width: 100%;
  height: 37px;
  border: none;
  padding: 0;
  background-color: transparent;
  min-width: 200px;
}

.timetracker-inner .project-name input::placeholder {
  color: #96a8ba;
}

.timetracker-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 24px;
  flex-direction: row;
}

.top-section-tracker .timetracker-inner {
  max-width: 10000px;
  /* overflow: auto; */
}

.overview-sec-timer-inner {
  /* display: grid;
    grid-template-columns: 1fr 1fr;*/
  grid-gap: 50px;
  display: flex;
  /* width: calc(100% - 600px); */
  /* width: -webkit-fill-available; */
  /* width: fill-available;  */
  flex: auto;
}

.project-name {
  width: 100%;
}

.overview-sec-timer-inner-right {
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
}

.timer-left {
  width: 100%;
}

.billable-timing svg path {
  fill: #405bff;
}

.time-tracker-outer {
  padding: 21px 45px 50px 42px;
}

.project-sprint {
  margin-left: 34px;
  padding-right: 18px;
  margin-right: 19px;
  position: relative;
  border-right: 1px solid #d8dde1;
  display: flex;
  align-items: center;
}

.project-sprint-outer {
  display: flex;
  padding: 8px 0px;
  grid-gap: 15px;
  color: #405bff;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  flex-direction: row;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}

.project-sprint-outer.col .cursor.col {
  display: flex;
  grid-gap: 15px;
}

.tag-section-outer {
  border-right: 1px solid #d8dde1;
  padding-right: 21px;
  margin-right: 19px;
  display: flex;
  align-items: center;
  /* position: relative; */
  height: 100%;
}

.tag-section {
  display: flex;
  padding: 6px 0px;
  position: relative;
}

.tag-section p {
  color: #95a3b8;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 15.86px;
  letter-spacing: 0.4px;
  border-radius: 100px;
  white-space: nowrap;
  border: 0.2px solid #677684;
  background: #f6f8fa;
  padding: 10px;
}

.billable-section {
  border-right: 1px solid #d8dde1;
  padding-right: 20px;
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.billable-timing-sec {
  padding: 5.5px 0;
  display: flex;
  align-items: center;
}

.timer-right {
  display: flex;
}

.col.start-timer-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
}

.timetracker-overview-outer.timetracker-outer-custom .start-timer-inner.col {
  grid-gap: 23px;
  padding-right: 9px;
}

.timetracker-overview-outer.timetracker-outer-custom {
  padding-right: 0;
}
span.tracker-project-tag-project {
  position: relative;
}
span.tracker-project-tag-project:after {
  content: "...";
  position: absolute;
  right: 0;
  background: #fff;
  z-index: 9;
  top: 0;
}

.start-timer-inner.col time {
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
  margin-right: 0px;
  min-width: 96px;
}

button.button-pad-start,
button.button-pad-stop {
  min-height: 44px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  background: #7cc273;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-transform: uppercase;
  min-width: 141px;
}

button.button-pad-stop {
  background-color: #fe6161 !important;
}

.project-name-tracker {
  color: #6a7988;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  position: relative;
}

.project-name-tracker:before {
  content: "";
  background: #6a7988;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 8px;
  left: -12px;
}

.start-timer-outer {
  display: flex;
  align-items: center;
}

.timer-date input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  padding: 10px;
  padding-left: 10px !important;
}

.timer-date input.rs-picker-toggle-textbox::placeholder {
  visibility: hidden;
  padding-left: 0 !important;
}

.timer-date .rs-picker-default .rs-picker-toggle.rs-btn {
  border: none !important;
  border-radius: 0;
  outline: none;
}

.timer-date .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default {
  border: none !important;
  outline: none !important;
  transition: none;
  box-shadow: none !important;
}

.timer-date input.rs-picker-toggle-textbox,
.timer-date span.rs-picker-toggle-placeholder {
  display: none;
}

.timer-date .rs-picker-toggle.rs-btn.rs-btn-default {
  padding: 0px 8px !important;
  width: auto !important;
  padding-right: 32px !important;
  /* border: 0.2px solid rgba(106, 121, 136, 0.70); */
  border: none;
  background: #fff;
  /* box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07); */
  border-radius: 0;
  height: 32px;
}

.timer-date .rs-btn-close .rs-icon {
  margin-top: 0 !important;
}

.timer-date span.rs-picker-toggle-clean.rs-btn-close {
  top: 6px !important;
  /* display: none; */
}

.timer-date span.rs-picker-toggle-value {
  color: #6a7988;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
}

.timer-date svg.rs-picker-toggle-caret.rs-icon {
  top: 4px !important;
}

.start-timer-inner.col input {
  border: none;
  width: auto !important;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  color: #282828;
  width: 65px !important;
}

.start-timer-inner.col input::placeholder {
  color: #282828;
}

.button-pad-add {
  background: transparent;
  padding: 0;
  margin-left: 22px;
}

.time-tracker-outer.time-tracker-outer-import-section .timetracker-inner.data-time-tracker {
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #fff;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  border-radius: 15px;
  margin: 15px 19px;
  padding: 12px 17px;
  padding-right: 19px !important;
  /* max-width: 10000px;
  overflow: auto; */
  position: relative;
  display: block;
  margin-bottom: 0px !important;
}

.tab-outer-time .project-sprint {
  position: initial;
}

.tab-outer-time .custom-dropdown {
  position: absolute;
  top: 100%;
  left: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 372px;
  transform: translate(-31%, 10px);
  z-index: 999;
  padding: 20px;
  padding-right: 10px !important;
  width: 372px;
  height: 408px;
}

.tab-outer-time .search-bar {
  width: 100%;
  border-radius: 5px;
  height: 40px;
  padding: 11px 18px;
  background-color: rgba(234, 235, 236, 0.7) !important;
  outline: none;
  margin-bottom: 30px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../../../images/icons/search-icon-dd.svg) no-repeat;
  background-position: left 18px center;
  cursor: pointer;
  padding-left: 47px;
  border: none;
}

.tab-outer-time .search-bar::placeholder {
  color: #6a7988;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 0.375px;
}

.tab-outer-time ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-outer-time ul li a {
  /* color: #282828 !important; */
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
}

.tab-outer-time ul li {
  /* margin-bottom: 20px; */
  text-align: left;
}

.tab-outer-time .small-arrow {
  margin-left: 8px;
}

.tab-outer-time .project-name-tracker {
  margin-left: 8px;
}

.manual-timer-change {
  text-align: left;
  margin-bottom: 30px;
}

.anual-timer-change-inner button {
  width: 137px;
  height: 40px;
  color: #96a8ba;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.52px;
  letter-spacing: 1px;
  border-radius: 100px;
  background: transparent;
  border: 1px solid #96a8ba;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
}

.anual-timer-change-inner {
  display: flex;
  grid-gap: 13px;
}

.anual-timer-change-inner button.active {
  background: #6479f8;
  color: #fff;
}

.anual-timer-change-inner button.active svg,
.anual-timer-change-inner button.active svg path {
  fill: #fff !important;
}

.time-overview-sec input {
  border: none;
  background: transparent;
  width: 72px;
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  text-align: center;
  padding: 10px 6px;
}

.time-overview-sec input.edit-time {
  border: none !important;
  padding: 10px 0 !important;
}

.custom-date-icon svg {
  position: initial !important;
  margin: 0 !important;
}

.custom-date-icon {
  position: relative;
}

.overview-sec-timer-date-time {
  position: relative;
  display: flex;
}

.overview-sec-timer-date-time
  .rs-anim-fade.rs-anim-in.placement-bottom-end.rs-picker-date-menu.rs-picker-menu {
  left: auto !important;
  right: 0 !important;
  top: 32px !important;
}

.overview-sec-timer-date-time .col {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding-right: 24px !important;
  border-right: 1px solid #d8dde1;
  margin-right: 11px;
}

.overview-time-edit .col {
  display: flex;
  align-items: center;
}

.overview-sec-timer-inner-right .tag-section-overview-outer {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.overview-time-edit {
  display: flex;
  align-items: center;
}

input.edit-time {
  font-weight: 400 !important;
}

.timer-sec-input input.edit-time {
  font-weight: 600 !important;
}

input.before-edit-input,
input.edit-time {
  border: none;
  background: transparent;
  padding: 10px;
  color: #282828;
  width: 85px;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
}

input.edit-time.edit-time-manual.edit-time-end {
  padding-left: 0;
  text-align: center;
  margin-left: 0;
}

input.edit-time.edit-time-manual.edit-time-start {
  padding-right: 0;
}

.start-timer-inner.col input {
  width: 74px !important;
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
}

input.edit-time {
  border: none !important;
  padding: 10px 0 !important;
  margin-left: 10px;
}

.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .custom-dropdown1 {
  position: relative;
  display: inline-block;
}

.overview-time-edit .dropdown-toggle1,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-toggle {
  cursor: pointer;
  display: flex;
}

.overview-time-edit .dropdown-menu1 {
  right: -15px !important;
  top: 50px !important;
}

.overview-time-edit .dropdown-menu1,
.timetracker-inner.data-time-tracker .overview-sec-timer-inner .dropdown-menu1 {
  position: absolute;
  top: 100%;
  right: -34px;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  width: max-content;
  width: 166px;
  height: auto;
  text-align: left;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 0px 0px 8px rgba(106, 121, 136, 0.3);
  margin-top: 13px;
  border-radius: 10px;
  z-index: 100;
  padding-right: 7px;
}

.overview-time-edit .dropdown-menu1:after,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-menu1:after {
  width: 15px;
  height: 15px;
  content: "";
  position: absolute;
  background: #ffffff;
  top: -8px;
  right: 28px;
  border-left: 0.2px solid rgba(106, 121, 136, 0.7);
  border-top: 0.2px solid rgba(106, 121, 136, 0.7);
  transform: rotate(45deg);
}

.overview-time-edit .dropdown-menu1 li,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-menu1
  li {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: #6a7988;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.14px;
  border-bottom: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  padding-top: 13px;
  padding-bottom: 13px;
}

.overview-time-edit .dropdown-menu1 li:last-child,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-menu1
  li:last-child {
  border: none !important;
  padding-bottom: 5px !important;
}

.overview-time-edit .dropdown-menu1 li:first-child,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-menu1
  li:first-child {
  padding-top: 5px;
}

.overview-time-edit .dropdown-menu1 li:hover,
.timetracker-inner.data-time-tracker
  .overview-sec-timer-inner
  .dropdown-menu1
  li:hover {
  opacity: 0.8;
}

.overview-time-edit .dropdown-menu1 li svg {
  margin-right: 8px;
}

.play-button button {
  background: transparent;
  padding: 0;
}

.overview-time-edit-inner {
  display: flex;
  align-items: center;
  grid-gap: 13px;
  padding-right: 32px !important;
  /* padding: 5px 0; */
  border-right: 1px solid #d8dde1;
  margin-right: 32px;
}

.overview-sec-timer-inner-right .tag-section-overview-outer .tag-section {
  padding: 0;
  position: initial !important;
}

.overview-sec-timer-inner-right .tag-section-overview-outer .tag-section p {
  padding: 0 10px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-self: center;
}

.manual-time-sec {
  display: flex;
  align-items: center;
  grid-gap: 26px;
}

.start-timer-inner.col input {
  width: 74px !important;
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
}

.time-overview-sec {
  display: flex;
  align-items: center;
}

.time-date-manual svg {
  display: none;
}

.manual-time-sec .date-overview-sec {
  display: flex;
  align-items: center;
  /* position: relative; */
}

.manual-time-sec span.rs-picker-toggle-value {
  color: #6a7988 !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 18.3px !important;
  letter-spacing: 1px !important;
}

input.edit-time.edit-time-manual {
  border: none !important;
  font-weight: 300 !important;
}

.manual-time-sec .rs-picker-toggle.rs-btn.rs-btn-default {
  padding-right: 0 !important;
}

.manual-time-sec {
  border-right: 1px solid #d8dde1;
  padding-right: 13px !important;
  margin-right: 19px !important;
}

.timetracker-overview-outer.timetracker-outer-custom .billable-section {
  margin-right: 9px;
}

.date-picker-hidden {
  display: none;
}

.date-overview-sec input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  padding-left: 10px !important;
}

.pagination-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-outer .pagination {
  padding: 0 !important;
}

.pagination-outer ul li {
  margin-bottom: 0 !important;
}

.pagination-outer .showing-result label {
  font-weight: 300;
  font-size: 12px;
  line-height: 14.64px;
  letter-spacing: 0.5px;
  color: #282828;
}

.pagination-outer .filter-inner.showing-result {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.pagination-outer .filter-inner.showing-result select {
  min-width: 44px;
  height: 27px;
  background-color: #fafafa;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.42px;
  letter-spacing: 0.5px;
  color: #282828;
  padding-left: 10px;
  background: url("../../../../images/icons/down-arrow-gray.png") no-repeat;
  background-position: right 8px center;
  padding-right: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}

.tag-billing {
  display: flex;
}

.total-time-tracked {
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  margin-bottom: 22px;
  color: #454f59;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24.4px;
  letter-spacing: 0.3px;
}

.total-time-date-right > div {
  min-width: 76.15px;
}

.total-time-date-right {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

/* .custom-dropdown {
  position: relative;
  width: 300px; 
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.client-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px !important;
}

/* .client-name,
  .project-name {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .client-name.selected,
  .project-name.selected {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .arrow {
    font-size: 14px;
  }
  
  .sprint-list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 20px; 
  }
  
  .sprint-list li {
    padding: 5px 0;
    cursor: pointer;
  } */
.client-name1 {
  font-size: 16px;
}

.client-name1,
.project-name1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  grid-gap: 20px;
  text-align: left;
}

.main-outer-item-dd {
  border-bottom: 0.5px solid #d8dde1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.main-outer-item-dd:last-child {
  margin-bottom: 8px;
  border: none;
}

.client-name1 span.arrow,
.project-name1 span.arrow {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.client-name1 span.arrow img,
.project-name1 span.arrow img {
  margin-top: 3px;
}

.project-name1-outer {
  padding-top: 9px;
}

.project-name1 span.main-project-title {
  font-weight: 300;
}

.project-dd-title {
  color: #282828;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 0.815px;
  cursor: pointer;
}

.project-name1 {
  padding: 10px 8px 10px 10px;
  margin-bottom: 0px;
}

.project-name1:hover {
  background: #f5f6f8;
}

ul.sprint-list li {
  padding: 10px 20px;
}

ul.sprint-list li:hover {
  background: #f5f6f8;
}

/* Add more styles as needed */
.timetracker-overview-outer.timetracker-outer-custom
  .timetracker-inner
  .project-name
  .cursor.col
  input {
  border: none;
  padding-left: 13px;
  min-width: 200px;
}

.main-project {
  color: #405bff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.74px;
  letter-spacing: 1px;
}

span.main-project-title {
  color: #6a7988;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.08px;
  white-space: nowrap;
}

input.time-show.edit-time-end {
  padding-left: 0;
  padding-right: 0;
  width: 75px;
}

input.time-show.edit-time-start {
  padding-right: 0;
  padding-left: 0;
  text-align: left;
}

input.before-edit-input {
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
  padding: 10px 0;
  margin-left: 10px;
}

.timetracker-overview-outer .custom-dropdown {
  top: 45px !important;
}

.timetracker-overview-outer .project-sprint-outer.col {
  display: flex !important;
}

ul.client-list li {
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  letter-spacing: 0.815px;
  cursor: pointer;
  margin-bottom: 0px;
  padding: 10px;
}

ul.client-list li.cursor:hover {
  background: #f5f6f8;
}

.manual-time-sec .date-overview-sec:before {
  content: "";
  background: transparent;
  position: absolute;
  width: 25px;
  height: 25px;
  z-index: 999;
  left: 0;
}

.overview-time-edit .col {
  /* position: relative; */
}

.set-btn-outer {
  width: 35px;
  height: 40px;
  position: relative;
}

button.set-button {
  position: absolute;
  right: 0px;
  bottom: 3px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #405bff;
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 99;
}

.timer-date.time-date-manual.timer-date.time-date-manual.rs-picker.rs-picker-date.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-has-value.rs-picker-cleanable {
  padding-left: 25px;
  position: relative;
  z-index: 99;
  /* background: transparent; */
  cursor: pointer;
}

.date-overview-sec.manual-tracker-outer-sec .custom-date-icon svg {
  position: absolute !important;
  top: -10px;
  z-index: 9;
  left: 0;
}

.rs-picker-toggle.rs-btn.rs-btn-default {
  background: transparent !important;
}

.date-overview-sec input.rs-picker-toggle-textbox {
  padding-left: 10px !important;
}

.date-overview-sec .rs-btn-close .rs-icon {
  margin-top: 0 !important;
}

.selected-option1 .value-field {
  position: static;
  transform: none;
}

.selected-option1 {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.overview-sec-timer-date-time
  .date-overview-edit
  .rs-picker.rs-picker-date.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-has-value.rs-picker-cleanable.rs-picker-block {
  width: 20px;
  visibility: hidden;
}

.overview-sec-timer-date-time
  .date-overview-edit
  svg.rs-picker-toggle-caret.rs-icon {
  position: absolute !important;
  left: 10px;
}
.tracker-top-outer-project.active {
  width: calc(100% - 2px) !important;
}
.project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
.timetracker-overview-outer
  .project-sprint-outer.col:hover
  span.tooltip-tracker,
p.cursor.tag-available:hover span.tooltip-tracker,
span.tooltip-tracker {
  /* display: flex; */
  position: absolute;
  padding: 0px 15px;
  grid-gap: 15px;
  max-width: fit-content !important;
  border-radius: 7px;
  background: #fafbfe;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  min-height: 33px;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  text-align: left;
}

.project-sprint-outer span.tooltip-tracker {
  transform: translate(-25%, -5px);
}

.timetracker-overview-outer
  .project-sprint-outer.col:hover
  span.tooltip-tracker {
  display: flex;
  /* top: -28px; */
}

.project-name .cursor.col:hover span.tooltip-tracker {
  display: flex;
}

.tag-section-outer .tag-section:hover span.tooltip-tracker {
  display: flex;
  white-space: nowrap;
}

span.tooltip-tracker {
  display: none;
}
.timer-left .project-name .cursor.col {
  position: relative;
}
.timer-left .project-name .dropdown {
  position: absolute;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 17px 12px 21px 12px;
  margin-top: 6px;
  z-index: 99;
}
.timer-left .project-name .dropdown ul li {
  display: flex;
  grid-gap: 16px;
  border-bottom: 1px solid #d8dde1;
  padding: 12px 4px 17px 4px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.timer-left .project-name .dropdown ul li:hover {
  opacity: 0.7;
}
.timer-left .project-name .dropdown ul li:first-child {
  padding-top: 0;
}
.timer-left .project-name .dropdown ul li:last-child {
  border: none;
  padding-bottom: 0 !important;
}
.timer-left .project-name .dropdown ul li .project-des {
  color: #282828;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
}
.timer-left .project-name .dropdown ul li .project-sug-name {
  color: #6479f8;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
}
.timer-left .project-name .dropdown ul li span.project-tag-sug {
  color: #6a7988;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.3px;
  letter-spacing: 1px;
  position: relative;
}
.timer-left .project-name .dropdown ul li span.project-tag-sug:before {
  content: ".";
  font-size: 26px;
  padding-right: 8px;
  margin-left: 6px;
}
.merge-tracker {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d8dde1;
  padding: 10px 11px 13px 5px;
  /* margin-bottom: 10px; */
}
.merge-tracker:first-child {
  padding-top: 0;
}
.merge-tracker.merge-tracker-iner .count-merge {
  visibility: hidden;
}
.merge-tracker .project-name {
  display: flex;
  align-items: center;
  grid-gap: 14px;
}
.merge-tracker .project-name .count-merge {
  color: #405bff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 1px;
  min-width: 22px;
  min-height: 22px;
  padding: 0 5px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #d1d8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.merge-tracker-outer {
  display: block;
}
.merge-tracker:last-child {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.tracker-top-outer-project.small {
  display: flex !important;
  grid-gap: 15px;
}
span.tooltip-tracker.small {
  display: none !important;
}
.project-tracker-inner {
  max-width: 225px;
}
.tracker-top-outer-project.large,
.project-tracker-inner.large {
  position: relative;
}
.tracker-top-outer-project.large:after,
.project-tracker-inner.large:after {
  content: "...";
  position: absolute;
  top: 0;
  z-index: 99;
  right: -2px;
  background: #fff;
  font-size: 20px;
  width: 19px;
}
.editer .project-tracker-inner.large:after {
  background-color: rgb(240, 247, 249) !important;
}
.project-tracker-inner.small {
  display: flex !important;
  grid-gap: 15px;
}
.project-name .cursor.col {
  flex-grow: 1;
}
.merge-tracker {
  position: relative;
}
.merge-tracker.merge-tracker-iner.editer {
  padding: 10px !important;
}
.tracker-filter-user.users label {
  color: #454f59;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.96px;
  letter-spacing: 1px;
  margin-top: 29px;
  display: block;
  margin-bottom: 11px;
}
.tracker-filter-user.users .custom-dropdown1 {
  max-width: 311px;
  width: 100% !important;
  cursor: pointer;
}
.tracker-filter-user.users .selected-options1 {
  width: 100%;
  max-width: 100%;
  padding-right: 30px;
  background-position: right 17px center;
}
.import-tracker-top-section {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 44px;
}
.import-tracker-top-section h2 {
  color: #454f59;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.72px;
  margin-bottom: 0;
}
.import-tracker-top-section .cancel-import-tracker img {
  width: 10.5px;
  height: 10.5px;
  margin-right: 0;
}
.import-tracker-top-section .cancel-import-tracker a.cancelbtn-link {
  grid-gap: 13.5px;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 0.5px;
}
.import-tracker-top-section .cancel-import-tracker button.cancelbtn-link {
  grid-gap: 13.5px;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  border: 0.2px solid #282828;
  border-radius: 6px;
  padding: 14px 15px 14px 16px;
  min-width: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1601px) {
  .overview-sec-timer-inner-right {
    justify-content: flex-start;
  }

  .outer-right-sec-overview {
    max-width: 10000px;
    overflow: auto;
  }

  .overview-sec-timer-inner {
    flex-grow: 1;
  }
}
.tag-section-mobile-section {
  display: flex;
}
button.button-confirm {
  border-radius: 6px;
  background: #6479f8;
  padding: 13.5px 15px;
  display: flex;
  align-items: center;
  grid-gap: 11.5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  min-width: 185.04px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-import {
  display: flex;
  justify-content: flex-end;
  margin-top: 38px;
}
.time-tracker-outer-import-section .total-time-tracked {
  display: none;
}
.tracker-data-import {
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #f5f6f8;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  margin-bottom: 38px;
}
.tracker-data-import .text-center.card-bg.card {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #d8dde1;
  padding-bottom: 36px;
  margin-bottom: 28px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: none;
}
.tracker-data-import
  .tracker-data-import-inner:first-child
  .card-header-sec.row {
  border-bottom: 0.5px solid #d8dde1;
}
.tracker-data-import
  .tracker-data-import-inner:last-child
  .text-center.card-bg.card {
  border-bottom: 0 !important;
  box-shadow: none;
  padding-bottom: 0;
}
.tracker-data-import-inner .card-header-sec.row {
  border: 0;
}
@media only screen and (max-width: 1199px) {
  .content-outer.time.tracker .tab-outer-time ul li {
    margin-bottom: 0;
  }
  .confirm-import {
    margin-left: 12px;
    margin-right: 12px;
  }
  .tracker-data-import {
    border-radius: 0 !important;
  }
  .content-outer.Time.Tracker .pagination-outer {
    padding: 0 20px;
  }
  .overview-time-edit .dropdown-menu1 {
    right: 10px !important;
    top: 100% !important;
  }
  .tracker-filter-user.users {
    padding: 0 10px;
  }
  .import-tracker-top-section {
    padding: 0 12px;
  }
  button.set-button {
    right: 0;
    bottom: 5px;
  }
  .merge-tracker {
    flex-wrap: wrap;
  }
  .content-outer.time.tracker .tab-outer-time ul.tabs {
    /* justify-content: space-around; */
  }

  .content-outer.time.tracker .time-tracker-outer {
    padding: 17px 0px !important;
    background: #fbfbfd;
  }

  .top-section-tracker {
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    background: #fff;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 20px 0px;
  }

  .anual-timer-change-inner {
    justify-content: center;
    grid-gap: 13px;
  }

  .timetracker-overview-outer {
    border: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 0px;
  }

  .timetracker-inner {
    flex-direction: column;
    grid-gap: 0;
  }

  .timer-right {
    flex-direction: column;
    width: 100%;
  }

  .tag-billing {
    justify-content: space-between;
    /* flex-wrap: wrap; */
    border-bottom: 1px solid #d8dde1;
    border-top: 1px solid #d8dde1;
    height: 57px;
    padding: 0;
    padding-left: 24px;
    padding-right: 10px;
    margin-bottom: 17px;
  }

  .tag-section-outer {
    width: calc(100% - 47px);
    margin-right: 23px;
    padding-left: 20px;
  }

  .billable-section {
    margin-right: 0;
    padding-right: 11px;
    border: none;
  }

  .timer-left {
    padding: 0 10px;
    padding-bottom: 21px;
  }

  .project-sprint-outer.col {
    padding: 0;
    font-size: 17px;
    grid-gap: 12px;
  }

  .project-sprint-outer.col img {
    width: 21.777px;
    height: 21.777px;
  }

  .tab-outer-time .project-sprint {
    border: none !important;
    padding-bottom: 27px;
  }

  .col.start-timer-inner {
    flex-direction: column;
    width: 100%;
    grid-gap: 21px;
    padding: 0 12px;
  }

  .start-timer-inner.col time,
  .start-timer-inner.col input {
    margin-right: 0;
    font-size: 24px;
    padding: 0;
    width: 100% !important;
    text-align: center;
  }

  button.button-pad-start,
  button.button-pad-stop {
    width: 100%;
    padding: 0 12px;
  }

  .button-pad-add {
    margin-left: 0 !important;
  }

  .top-section-tracker {
    margin-bottom: 24px !important;
    margin: 0 12px;
  }

  .manual-timer-change {
    margin-bottom: 18px;
  }

  .tab-outer-time .custom-dropdown {
    width: 100% !important;
    left: 0;
    min-width: 100%;
    transform: none;
  }

  .tab-outer-time .custom-dropdown ul li {
    margin-bottom: 20px !important;
  }

  .manual-time-sec {
    margin-right: 0 !important;
    border: none;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    height: 57px;
    border-bottom: 1px solid #d8dde1;
    margin-bottom: 20px;
  }

  .manual-time-sec .time-overview-sec {
    width: 55%;
    border-right: 1px solid #d8dde1;
    height: 100%;
  }

  .manual-time-sec .date-overview-sec {
    width: 40%;
  }

  .timetracker-overview-outer.timetracker-outer-custom .tag-billing {
    margin-bottom: 0 !important;
  }

  .card-header-sec.row {
    border-bottom: none;
  }

  .overview-sec-timer-inner {
    flex-direction: column;
    grid-gap: 10px;
    margin-bottom: 30px;
    width: 100%;
  }

  .outer-right-sec-overview {
    max-width: 10000px;
    overflow-x: auto;
    width: 100%;
    margin: 0 15px !important;
    margin-right: 0 !important;
    display: flex;
  }
  .tag-section-mobile-section {
    display: flex;
  }
  .timetracker-inner.data-time-tracker.overview-sec-timer .project-name input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .card-bg {
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    background: #f5f6f8;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    border-radius: 0;
    border-left: 0;
    border-radius: 0;
  }

  .body-mg.card-body {
    margin: 0 12px;
  }

  .timetracker-inner.data-time-tracker {
    margin: 0 !important;
    margin-bottom: 15px !important;
    padding: 17px 0 !important;
  }

  .overview-sec-timer-inner .project-sprint-outer.col {
    display: none;
  }

  .project-sprint-outer-mobile {
    display: flex !important;
    align-items: center;
    margin-right: 20px;
    position: initial;
  }

  .overview-sec-timer-inner .custom-dropdown1 {
    display: block !important;
  }

  .timetracker-inner.data-time-tracker .overview-sec-timer-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 20px;
    align-items: center;
    padding: 0 15px !important;
    border-bottom: 0.2px solid #d8dde1;
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
    padding-right: 33px !important;
  }

  .project-name {
    width: 100%;
  }

  .overview-time-edit .custom-dropdown1 {
    display: none;
  }

  .overview-time-edit-inner {
    border: none;
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .overview-sec-timer-inner .custom-dropdown1 {
    margin-top: 5px;
  }

  .timetracker-inner.data-time-tracker {
    position: relative;
  }

  .overview-sec-timer-inner-right .tag-section-overview-outer .tag-section {
    position: initial !important;
  }

  .rs-anim-fade.rs-anim-in.placement-bottom-end.rs-picker-date-menu.rs-picker-menu {
    /* left: auto !important; */
    /* width: 90%; */
  }

  .tab-outer-time .custom-dropdown {
    top: auto;
  }
  .merge-tracker.merge-tracker-iner .count-merge {
    display: none;
  }
  .merge-tracker.merge-tracker-iner .overview-sec-timer-inner {
    padding-left: 10px !important;
  }
  .timetracker-inner.data-time-tracker
    .overview-sec-timer-inner
    .dropdown-menu1 {
    width: 218px;
  }

  .overview-sec-timer-inner-right {
    width: 100%;
    justify-content: space-between;
  }

  .overview-time-edit-inner {
    margin-right: 20px !important;
  }

  .card-header-sec.row {
    padding-left: 21px;
  }

  .total-time-tracked {
    padding: 0 21px;
  }
}

.project-sprint-outer.col.project-sprint-outer-mobile {
  margin-right: 14px;
}
p.cursor.tag-available {
  margin-left: 12px;
}
@media only screen and (max-width: 1250px) and (min-width: 1200px) {
  .project-sprint-outer.col {
    display: none;
  }
  .project-sprint-outer.col.project-sprint-outer-mobile {
    display: flex !important;
    margin-right: 14px;
    grid-gap: 5px;
  }
}
@media only screen and (max-width: 1450px) and (min-width: 1200px) {
  .project-tracker-inner {
    max-width: 110px !important;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1451px) {
  .project-tracker-inner {
    max-width: 138px !important;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  span.project-tracker-inner.small {
    position: relative;
  }
  span.project-tracker-inner.small:after {
    content: "...";
    background-color: #fff;
    position: absolute;
    right: 0;
  }
  span.tooltip-tracker.small {
    display: none !important;
  }
  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker.small,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex !important;
  }
  .overview-sec-timer-inner {
    grid-gap: 20px !important;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  .outer-right-sec-overview {
    max-width: 10000px;
    overflow: auto;
    /* padding-bottom: 10px; */
  }
  .overview-sec-timer-inner {
    grid-gap: 20px !important;
  }

  .overview-sec-timer-inner-right {
    justify-content: flex-start;
  }

  .timetracker-inner.data-time-tracker {
    position: relative;
  }

  .project-sprint-outer,
  .tag-section,
  .overview-time-edit .custom-dropdown1 {
    position: initial;
  }

  .overview-time-edit .dropdown-menu1 {
    top: 45px;
    right: -15px;
  }

  .tab-outer-time .custom-dropdown {
    top: 67px;
    left: auto;
  }

  .outer-right-sec-overview::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  .outer-right-sec-overview::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .outer-right-sec-overview::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .outer-right-sec-overview::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.manual-tracker-outer-sec
  .rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-bottom-end {
  left: auto !important;
  right: 10px;
}

p.cursor.tag-not-available {
  background: transparent;
  border: none;
}

p.cursor.tag-not-available:after {
  display: none;
}
.project-sprint.hide-on-small-desktop span.tracker-project-tag-project:after,
.project-sprint.hide-on-large-desktop span.tracker-project-tag-project:after {
  display: none;
}
@media only screen and (max-width: 1454px) and (min-width: 1200px) {
  .tag-section p span {
    max-width: 50px;
    overflow: hidden;
  }

  .project-sprint-outer.col .cursor.col {
    overflow: hidden;
    max-width: 140px !important;
  }

  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker {
    display: flex;
  }
}

@media only screen and (max-width: 1630px) and (min-width: 1601px) {
  .project-sprint-outer.col .cursor.col {
    overflow: hidden;
    max-width: 150px;
  }

  /* .project-sprint-outer.col.project-sprint-outer-mobile:after {
    content: "...";
  } */
  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex;
  }
}

@media only screen and (min-width: 1631px) {
  .tag-section p span {
    max-width: 100px !important;
    overflow: hidden;
  }

  /* .tag-section p:after{
    content: "...";
    padding-left: 5px;
  } */
  .project-sprint-outer.col .cursor.col {
    overflow: hidden;
    max-width: 300px;
  }

  /* .project-sprint-outer.col.project-sprint-outer-mobile:after {
    content: "...";
  } */
  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1455px) {
  .tag-section p span {
    max-width: 100px !important;
    overflow: hidden;
  }

  /* .tag-section p:after{
    content: "...";
    padding-left: 5px;
  } */
  .project-sprint-outer.col .cursor.col {
    overflow: hidden;
    max-width: 200px;
  }

  /* .project-sprint-outer.col.project-sprint-outer-mobile:after {
    content: "...";
  } */
  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 985px) {
  .tracker-top-outer-project.small {
    max-width: 150px !important;
    display: block !important;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 985px) {
  .tag-section p span {
    max-width: 80px !important;
    overflow: hidden;
  }

  .project-sprint-outer.col.project-sprint-outer-mobile {
    /* overflow: hidden; */
    max-width: 100px;
    margin-right: 40px;
  }

  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex;
  }
}

@media only screen and (max-width: 1239px) and (min-width: 1199px) {
  .timetracker-inner.data-time-tracker.overview-sec-timer .project-name input {
    min-width: auto !important;
  }

  .project-sprint-outer.col.project-sprint-outer-mobile
    span.project-tracker-inner {
    max-width: 40px !important;
  }
}

@media only screen and (max-width: 1294px) and (min-width: 1240px) {
  span.project-tracker-inner {
    max-width: 85px !important;
  }

  .timetracker-inner.data-time-tracker.overview-sec-timer .project-name input {
    min-width: auto !important;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 1295px) {
  .timetracker-inner.data-time-tracker.overview-sec-timer .project-name input {
    /* max-width: 100px !important; */
    overflow: hidden;
    width: 100%;
    min-width: 100px;
  }

  .project-sprint-outer.col .cursor.col:hover span.tooltip-tracker,
  p.cursor.tag-available:hover span.tooltip-tracker,
  .timetracker-overview-outer
    .project-sprint-outer.col:hover
    span.tooltip-tracker {
    display: flex;
  }
}

@media only screen and (max-width: 1644px) and (min-width: 1601px) {
  span.tag-edit-tag-name {
    max-width: 130px !important;
  }
}

@media only screen and (max-width: 1468px) and (min-width: 1380px) {
  span.tag-edit-tag-name {
    max-width: 90px !important;
  }

  .project-name-edit-inner {
    max-width: 110px !important;
  }

  .overview-time-edit-inner {
    padding-right: 16px !important;
    margin-right: 16px !important;
  }

  .time-overview-sec input.edit-time {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1379px) and (min-width: 1300px) {
  span.tag-edit-tag-name {
    max-width: 50px !important;
  }

  .project-name-edit-inner {
    max-width: 70px !important;
  }

  .overview-time-edit-inner {
    padding-right: 16px !important;
    margin-right: 16px !important;
  }

  .time-overview-sec input.edit-time {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 1380px) and (min-width: 1300px) {
  .time-tracker-outer {
    padding: 21px 34px 50px 27px;
  }
}
@media only screen and (max-width: 1299px) and (min-width: 1220px) {
  span.tag-edit-tag-name {
    max-width: 50px !important;
  }

  .project-name-edit-inner {
    max-width: 50px !important;
  }

  .overview-time-edit-inner {
    padding-right: 16px !important;
    margin-right: 16px !important;
  }

  .time-overview-sec input.edit-time {
    margin-left: 0 !important;
  }

  .billable-section {
    padding-right: 10px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .overview-sec-timer-inner-right .tag-section-overview-outer {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 1200px) {
  .merge-tracker {
    grid-gap: 10px !important;
  }
}
@media only screen and (max-width: 1219px) and (min-width: 1200px) {
  span.tag-edit-tag-name {
    max-width: 40px !important;
  }

  .project-name-edit-inner {
    max-width: 40px !important;
  }

  .overview-time-edit-inner {
    padding-right: 16px !important;
    margin-right: 16px !important;
  }

  .time-overview-sec input.edit-time {
    margin-left: 0 !important;
  }

  .billable-section {
    padding-right: 10px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .overview-sec-timer-inner-right .tag-section-overview-outer {
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: 1200px) {
  .tab-outer-time .project-sprint,
  .tag-billing {
    height: 40px;
  }

  .timer-right {
    align-items: center;
  }
}
@media only screen and (max-width: 1199px) {
  .time-tracker-outer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  .time-tracker-outer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
/*************Responsive Styles*****************/
/***************iPhone Portrait**************/
@media only screen and (max-width: 479px) and (min-width: 220px) {
  .total-time-date-right {
    display: none;
  }
  .tracker-filter-user.users .custom-dropdown1 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .content-outer.time.tracker ul.tabs {
    /* padding-bottom: 0 !important; */
  }

  ul.tabs.team-tab-s1 {
    padding-bottom: 10px;
  }

  .content-outer.time.tracker ul.tabs {
    padding-bottom: 0 !important;
  }

  .pagination-outer {
    flex-direction: column;
    grid-gap: 30px;
    padding-bottom: 30px;
  }

  .showing-count {
    width: 100%;
  }

  .rs-anim-fade.rs-anim-in.placement-bottom-end.rs-picker-date-menu.rs-picker-menu {
    left: auto !important;
    width: 95% !important;
    transform: translate(10px, 0px);
  }

  .overview-time-edit {
    padding-right: 20px;
  }

  .pagination-outer {
    padding: 0 20px;
  }
  .time-tracker-outer {
    padding: 21px 0px 50px 0px;
  }
}

/**********************iPhone Landscape*************/
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .pagination-outer {
    flex-direction: column;
    grid-gap: 30px;
    padding-bottom: 30px;
  }
  .tracker-filter-user.users .custom-dropdown1 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .time-tracker-outer {
    padding: 21px 0px 50px 0px;
  }
  .overview-time-edit {
    padding-right: 20px;
  }

  .pagination-outer {
    padding: 0 20px;
  }
}

/*************************iPad Portrait***************/
@media only screen and (max-width: 985px) and (min-width: 768px) {
  .overview-time-edit {
    padding-right: 20px;
  }

  .pagination-outer {
    padding: 0 20px;
  }
  .time-tracker-outer {
    padding: 21px 0px 50px 0px;
  }
}
@media only screen and (max-width: 1199px) {
  .project-sprint.hide-on-large-desktop {
    /* display: none; */
    padding-bottom: 7px;
    padding-top: 20px;
    margin: 0 15px;
  }
  .tag-section-outer {
    padding-left: 0;
  }
  .project-sprint.hide-on-large-desktop span.tracker-project-tag-project {
    display: flex !important;
    max-width: 100% !important;
    grid-gap: 10px 20px !important;
    flex-wrap: wrap;
    overflow: visible !important;
  }
  .tab-outer-time .project-sprint {
    margin-left: 20px;
  }
  .timetracker-overview-outer.timetracker-outer-custom .project-sprint {
    margin-left: 15px;
  }
  .timetracker-overview-outer.timetracker-outer-custom
    .timetracker-inner
    .project-name
    .cursor.col
    input,
  .timetracker-overview-outer
    .timetracker-inner
    .project-name
    .cursor.col
    input {
    border-radius: 7px;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    background: #fff;
    height: 48px;
  }
  .inner-left-samll-device {
    display: none;
  }
}
/**************iPad Landscape***************/
@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .hide-on-samll-desktop {
    display: none;
  }
  .timetracker-inner.small-device-desktop .timer-left {
    display: flex;
  }
  .timetracker-inner.small-device-desktop {
    flex-wrap: wrap;
  }
  .timetracker-inner.small-device-desktop .timer-left {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 19px 30px 0 17px;
  }
  .project-sprint.hide-on-large-desktop {
    border-left: 1px solid #d8dde1;
    padding-left: 21px;
    border-right: 0;
    margin-right: 0;
  }
  .timetracker-inner.small-device-desktop .timer-right {
    width: 100%;
    border-top: 1px solid #d8dde1;
    padding-top: 20px;
    padding: 20px 15px 21px 35px;
  }
  .timetracker-inner.small-device-desktop
    .timer-right
    .outer-small-device-timer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .timetracker-inner.small-device-desktop
    .timer-right
    .outer-small-device-timer
    .inner-right-small-device {
    display: flex;
    align-items: center;
  }
  .timetracker-inner.small-device-desktop
    .inner-left-samll-device
    .tag-section-outer {
    border-right: 0;
    margin: 0;
    position: relative;
  }
  .timetracker-inner.small-device-desktop
    .inner-left-samll-device
    .tag-section-outer
    .custom-dropdown {
    left: 80px !important;
  }
  .timetracker-overview-outer.timetracker-outer-custom {
    padding: 0;
  }
  .inner-right-small-device .tag-billing {
    height: 40px;
  }
  .inner-right-small-device .tag-section-outer {
    display: none;
  }
  .project-sprint.hide-on-large-desktop span.tracker-project-tag-project {
    max-width: 200px !important;
  }
  .inner-left-samll-device .tag-section p {
    max-width: 250px !important;
  }
}
@media only screen and (min-width: 1441px) {
  .hide-on-large-desktop {
    display: none;
  }
  .outer-small-device-timer,
  .inner-right-small-device {
    display: flex;
  }
  .inner-left-samll-device .tag-section-outer {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  span.project-tracker-inner {
    text-align: right;
  }
}
