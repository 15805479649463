.outer-account {
    padding: 29px 26px 29px 31px;
}

.outer-account .account-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 29px;
}

.outer-account .account-top .search-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../../images/icons/search-icon.svg') no-repeat;
    background-position: left 15px center;
    cursor: pointer;
    padding-left: 58px;
    background-color: #EAEBEC;
    border-radius: 10px;
    width: 247px;
    height: 45px;
    border: none;
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #6A7988;
    padding-right: 15px;
}
.ReactModal__Content.ReactModal__Content--after-open.userAccount-modal.bank-account-mod button {
    margin-bottom: 30px;
}
.outer-account .button-sec-account a {
    background: #6479F8;
    border-radius: 6px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 44px;
}

.outer-account .filter-inner.showing-result label {
    font-weight: 300;
    font-size: 12px;
    line-height: 14.64px;
    letter-spacing: 0.5px;
    color: #282828;
}

.outer-account .filter-inner.showing-result {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
}

.outer-account .filter-inner.showing-result select {
    min-width: 44px;
    height: 27px;
    background-color: #FAFAFA;
    border: 0.5px solid #B4B4B4;
    border-radius: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13.42px;
    letter-spacing: 0.5px;
    color: #282828;
    padding-left: 10px;
    background: url('../../../../images//icons/down-arrow-gray.png') no-repeat;
    background-position: right 8px center;
    padding-right: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.account-table {
    margin-top: 20px;
    max-width: 100000px;
    overflow: auto;
    background: #fff;
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    padding: 25px 28px;
    border-radius: 10px;
}

.account-table table {
    width: 100%;
    border-collapse: collapse;
}

.account-table th,
.account-table td {
    padding: 20px;
    text-align: left;
    white-space: nowrap;
}

.account-table th {
    background-color: #f5f6f8;
    font-weight: 500;
    font-size: 17px;
    line-height: 20.74px;
    color: #6A7988;
    padding-top: 11px;
    padding-bottom: 12px;
}

.account-table thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 22px;
}

.account-table tr td:first-child {
    padding-left: 22px;
}

.account-table tr td:last-child {
    padding-right: 8px;
}

.account-table thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 8px;
}

.account-table td {
    border-bottom: 0.5px solid #d8dde1;
    padding-top: 21px;
    padding-bottom: 21px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #282828;
}

.account-table img {
    vertical-align: middle;
    margin-left: 5px;
}

.account-table tbody tr a {
    font-weight: 300;
    font-size: 15px;
    line-height: 18.3px;
    letter-spacing: 1px;
    color: #FFFFFF;
    width: 76px;
    height: 31px;
    background: #6479F8;
    border-radius: 8px;
}

.account-table tbody tr a {
    font-weight: 300;
    font-size: 15px;
    line-height: 18.3px;
    letter-spacing: 1px;
    color: #FFFFFF;
    width: 76px;
    height: 31px;
    background: #6479F8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-table tbody tr td:last-child {
    text-align: -webkit-right;
}

.account-table tbody tr td span.active {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #7CC273;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.account-table tbody tr td span.inactive {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #96A8BA;
    background: #EAEAEA;
    border: 0.2px solid #6A7988;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.modal-top-outer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-close .close-modal img {
    height: 12px;
    width: 12px;
}


.modal-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 22.44px;
    color: #282828;
    margin-bottom: 24px;
}

.ReactModal__Content.ReactModal__Content--after-open.userAccount-modal {
    width: 68% !important;
    height: max-content !important;
    overflow: auto;
    padding-top: 15px;
    padding-left: 24px !important;
    padding-right: 12px !important;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar {
    width: 12px;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
}

.toggle-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.toggle-button.selected {
    background-color: green;
}

.custom-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-bottom: 0;
}

.custom-switch-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    border-radius: 24px;
    transition: 0.4s;
}

.custom-switch-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

.custom-switch-input:checked+.custom-switch-slider {
    background-color: #405BFF;
}

.custom-switch-input:focus+.custom-switch-slider {
    box-shadow: 0 0 1px #405BFF;
}

.custom-switch-input:checked+.custom-switch-slider:before {
    transform: translateX(26px);
}

.custom-switch-description {
    position: relative;
    margin-left: 8px;
    line-height: 24px;
    cursor: pointer;
    color: #777;
}

.custom-switch-description:hover {
    color: #405BFF;
}

span.custom-switch-slider {
    background: #96A8BA;
}

button.remove-user-button {
    background: transparent;
    padding: 0;
}

button.remove-user-button img {
    margin: 0;
}

@media only screen and (max-width: 540px) and (min-width: 220px) {
    .outer-account .account-top {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px;
    }

    .outer-account .button-sec-account a,
    .outer-account .search-container,
    .outer-account .search-sec-account,
    .outer-account .account-top .search-container input,
    .outer-account .button-sec-account {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .outer-account {
        padding: 29px 17px 29px 17px;
    }

    .account-table {
        padding: 0;
    }
}